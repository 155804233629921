import React, {useEffect, useState} from 'react';
import Axios from "axios"
import {useHistory} from 'react-router-dom';
import {mainUrl, webUrl} from "../../common/common";
import "./../../assets/css/bootstrap.min.css"
import "./Login.css"
import toast, {Toaster} from 'react-hot-toast';


import Atarud from "./../../assets/img/atarudpng.jpg";
import ARIADELTA from "./../../assets/img/aria_delta.png";

function Login() {
    const [data, setData] = useState({
        email: "",
        password: ""
    })
    useEffect(() => {
        sessionStorage.setItem('dir', "english");
    }, []);

    function handle(e) {
        const newdata = {...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    const history = useHistory();

    function submit(e) {
        e.preventDefault();
        notifyLoading("Loading . . .");
        Axios.post(mainUrl + 'login', {
            email: data.email,
            password: data.password,
        }, {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Header': '*'
        }).then(res => {
            var elements = document.getElementsByClassName("go2072408551");
            elements[0].style.display = 'none';
            notifySuccess(res.data.message);
            setTimeout(() => {
                sessionStorage.setItem("token", res.data.token);
                sessionStorage.setItem("user_profile_image", res.data.image);
                sessionStorage.setItem("user_profile_name", res.data.name);
                sessionStorage.setItem("user_profile_email", res.data.email);
                return history.push('/dashboard');
            }, 3000);
        }).catch(function (error) {
            try{
                var elements = document.getElementsByClassName("go2072408551");
                elements[0].remove();
                notifyError(error.response.data.message)
            }catch(error){
                notifyError("Error Network!");
            }

        });
    }

    const notifyError = (message) => toast.error(message);
    const notifySuccess = (message) => toast.success(message);
    const notifyLoading = (message) => toast.loading(message,{
        duration: 3000,
    });

    const forgotUrl = webUrl + "changePassword";
    return (
        <>
            <Toaster position="top-right" reverseOrder={true}/>
            <div className="container" style={{paddingTop: "1%"}}>

                <div className="row">
                    <div className="col align-self-start">
                    </div>
                    <div className="col align-self-center">
                        <form className="Auth-form" onSubmit={(e) => submit(e)}>
                            <div className="Auth-form-content">
                                <div className="Auth-form-title">
                                    <img src={Atarud} style={{width: "100px", height: "auto"}}/><br/>
                                    <h1 style={{color: '#FF6E40FF'}}> Sign in to Atarud MIS </h1>
                                </div>
                                <div className="form-group mt-3">
                                    <label>Email address</label>
                                    <input onChange={(e) => handle(e)} id="email" value={data.email}
                                           type="email"
                                           className="form-control mt-1"
                                           title="Enter Your Email Address"
                                           placeholder="Enter Your Email Address"
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Password</label>
                                    <input onChange={(e) => handle(e)} id="password" value={data.password}
                                           type="password"
                                           className="form-control mt-1"
                                           title="Enter your password Account"
                                           placeholder="Enter your password Account"
                                    />
                                </div>
                                <div className="d-grid gap-2 mt-3">
                                    <button type="submit" className="btn " style={{backgroundColor: '#FF6E40FF'}}
                                            title="Submit">
                                        Submit
                                    </button>
                                </div>
                                <p className="forgot-password text-center text-right mt-2">
                                    <a className="" title="forgot Password" style={{color: '#ffffff'}} target="_blank"
                                       href={forgotUrl}>Forgot Password</a>
                                </p>
                            </div>
                        </form>
                    </div>
                    <div className="col align-self-end">
                    </div>
                </div>

            </div>
            <div className="row " id="footer">
                <div>

                    <div className="col align-self-start">
                    </div>
                    <div className="col align-self-center ">

                        <a target="_blank" href="https://ariadelta.af/"><img src={ARIADELTA} alt="Aria Delta" height="40" width="auto" /></a>&nbsp;&nbsp;&nbsp;&nbsp;

                        <div className="copyright p-3  " style={{fontSize: "8pt",color:"#FF6E40FF"}}>
                            Copyright &copy; {2022}  by Aria Delta Consulting Group<br/>
                            All rights reserved.
                            {/*Copyright &copy; {2022} by WADAN<br/>*/}
                            {/*All rights reserved.*/}
                        </div>
                    </div>

                    <div className="col align-self-end">
                    </div>

                </div>

            </div>
        </>
    );
}

export default Login;
