import React from "react";
import Position from "./Setup/Position";
import Branch from "./Branch";
import {CardHeader, CardBody, CardTitle, Button, Input} from "reactstrap";
import {Tabs, Card, Row, Col, Tab, FormGroup, Modal} from "react-bootstrap";
import {Toaster} from "react-hot-toast";
import Project from "./Project";
import Department from "./Setup/Department";
import Year from "./Setup/Year";
import Program from "./Setup/Program";
import Section from "./Setup/Section";

function Setup() {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    return (
        <>
            <Row>
                <Col md="3"></Col>
                <Col className="pr-3" md="6">
                    <div className=" ">
                        <CardBody className="all-icons mr-4 ml-4">

                            <div style={{marginTop: '20px', paddingBottom: '50px   '}}>
                                <Row>
                                    <h5>Institute basic setting</h5>
                                </Row>
                                <Row>
                                    <Col md="2">
                                    </Col>
                                    <Col  md="4">
                                        <FormGroup>
                                            <label>System Name <span className="text text-danger">*</span></label>
                                            <Input required name="System Name" id="system_name" required
                                                   placeholder="System Name"
                                                   title="System Name" type="text"/>
                                        </FormGroup>
                                    </Col>
                                    <Col  md="4">
                                        <FormGroup>
                                            <label>Registration Number <span
                                                className="text text-danger">*</span></label>
                                            <Input required name="registration_number" id="registration_number"
                                                   placeholder="Registration Number" title="Registration Number"
                                                   type="text"/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="2">
                                    </Col>
                                    <Col   md="4">
                                        <FormGroup>
                                            <label>Email<span className="text text-danger">*</span></label>
                                            <Input required name="email" id="email"
                                                   placeholder="Email" title="Email"
                                                   type="email"/>
                                        </FormGroup>
                                    </Col>
                                    <Col  md="4">
                                        <FormGroup>
                                            <label>Remark <span className="text text-danger">*</span></label>
                                            <textarea className="form-control" placeholder="Remark . . ."
                                                      name="remark" id="remark"></textarea>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <h5>System ID’s Configuration </h5>
                                </Row>
                                <Row>
                                    <Col md="2">
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label></label>
                                            <h6>Students</h6>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label>Prefix <span className="text text-danger">*</span></label>
                                            <Input required name="student_prefix" id="student_prefix" required
                                                   placeholder="Student Prefix"
                                                   title="Student Prefix" type="text"/>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label>Suffix <span className="text text-danger">*</span></label>
                                            <Input required name="student_suffix" id="student_suffix"
                                                   placeholder="Student Suffix" title="Student Suffix"
                                                   type="text"/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="2">
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label></label>
                                            <h6>Vouchers</h6>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label>Prefix <span className="text text-danger">*</span></label>
                                            <Input required name="voucher_prefix" id="voucher_prefix" required
                                                   placeholder="Voucher Prefix"
                                                   title="Voucher Prefix" type="text"/>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label>Suffix <span className="text text-danger">*</span></label>
                                            <Input required name="voucher_suffix" id="voucher_suffix"
                                                   placeholder="Voucher Suffix" title="Voucher Suffix"
                                                   type="text"/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="2">
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label></label>
                                            <h6>Employees</h6>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label>Prefix <span className="text text-danger">*</span></label>
                                            <Input required name="employee_prefix" id="system_name" required
                                                   placeholder="Employee Prefix" title="Employee Prefix"
                                                   type="text"/>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label>Suffix <span className="text text-danger">*</span></label>
                                            <Input required name="employee_suffix" id="employee_suffix"
                                                   placeholder="Employee Suffix" title="Employee Suffix"
                                                   type="text"/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="2">
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label></label>
                                            <h6>Teachers</h6>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label>Prefix <span className="text text-danger">*</span></label>
                                            <Input required name="teacher_prefix" id="teacher_prefix" required
                                                   placeholder="Teacher Prefix"
                                                   title="Teacher Prefix" type="text"/>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="2">
                                        <FormGroup>
                                            <label>Suffix <span className="text text-danger">*</span></label>
                                            <Input required name="teacher_suffix" id="teacher_suffix"
                                                   placeholder="Teacher Suffix" title="Teacher Suffix"
                                                   type="text"/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="modal-footer">
                                        <Button title="Insert & update  Record" className="btn-round" color="danger"
                                                type="submit">
                                            Save
                                        </Button>
                                    </div>
                                </Row>
                            </div>

                        </CardBody>
                    </div>
                </Col>
            </Row>

        </>
    );

}

export default Setup;
