import React, {useEffect, useState} from "react";

import {Button, Col, FormGroup, Modal, Row, Spinner} from "react-bootstrap";
import image from "../../assets/img/image.png";
import {Card, Input} from "reactstrap";
import Axios from "axios";
import {mainUrl, toBase64, dataURLtoFile} from "../../common/common.js";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Teacher from "./Teacher/Teacher";

function AddClass(props) {

    const [classRoomList, setClassRoomList] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [statusRecord, setStatusRecord] = useState(true);

    useEffect(() => {
        if (props.dataForm.id) {
            setStatusRecord(false)
        } else {
            setStatusRecord(true)
        }
    });

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addClass = async (e) => {
        e.preventDefault();
        const f = e.target;
        await Axios.post(mainUrl + "addClass", {
            id: f.ids.value,
            status: f.status.value,
            end_time: f.end_time.value,
            start_time: (f.start_time.value),
            start_date: (f.start_date.value),
            remark: f.remark.value,
            program_id: f.program_id.value,
            department_id: f.department_id.value,
            branch_id: f.branch_id.value,
            year_id: f.year_id.value,
            class_code: f.class_code.value
        }, {headers}).then(res => {
            if (f.ids.value == 0) {
                props.notifySuccess(' Added Successfully.');
            } else {
                props.notifySuccess(' Updated Successfully.');
            }
            document.querySelector(".main-panel")
                .scrollTo(0, 0);
            props.setDataForm(res.data);
            setStatusRecord(false);
            props.setUpdateRecordStatus(false);
        }).catch(function (error) {
            props.notifyError(error.response.data.message);
        });
    };

    const onDepartmentChange = async (e) => {
        // e.preventDefault();
        let newArray = props.programList.filter(function (el) {
            return el.department_id == e.target.value;
        }).map(a => a.id);
        let res = props.programList.filter(function (el) {
            return newArray.includes(el.id);
        })
        setProgramList(res);
    };
    const onBranchChange = async (e) => {
        // e.preventDefault();
        let newArray = props.classRoomList.filter(function (el) {
            return el.branch_id == e.target.value;
        }).map(a => a.id);
        let res = props.classRoomList.filter(function (el) {
            return newArray.includes(el.id);
        })
        setClassRoomList(res);
    };

    return (
        <>
        <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
            <Modal.Header className="justify-content-center">
                <div className="modal-profile">
                    Add or Modify Class
                </div>
            </Modal.Header>
            <Modal.Body>
                <div style={{height: '500px', overflowY: 'auto', marginTop: '20px', paddingBottom: '50px   '}}>
                    <form onSubmit={(e) => addClass(e)}>
                        <Row className="pr-3">
                            <Col md="6">
                                <Input type="hidden" className="" name="ids" id="ids"
                                       defaultValue={props.dataForm.id}/>
                                <FormGroup className={props.dataForm.id==null && "visually-hidden"} >
                                    <label>Class Code</label>
                                    <Input readOnly={true}  name="class_code" id="class_code"
                                           value={"CLS-"+("0000" + props.dataForm.id).slice(-5)}
                                           placeholder="Class Code" type="text"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="pr-3">
                            <Col md="3">
                                <FormGroup>
                                    <label>Academic Year <span className="text text-danger">*</span></label>
                                    <select title="Academic Year" name="year_id" id='year_id'
                                            className="form-control" defaultValue={props.dataForm.year_id}>
                                        {
                                            props.yearList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <label>Branch <span className="text text-danger">*</span></label>
                                    <select title="Select Branch" name="branch_id" id='branch_id'
                                            onChange={e => onBranchChange(e)}
                                            className="form-control" defaultValue={props.dataForm.branch_id}>
                                        <option value=""></option>
                                        {
                                            props.branchList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <label>Department <span className="text text-danger">*</span></label>
                                    <select title="Select Department" name="department_id" id='department_id'
                                            onChange={e => onDepartmentChange(e)}
                                            className="form-control" defaultValue={props.dataForm.department_id}>
                                        <option value=""></option>
                                        {
                                            props.departmentList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <label>Program<span className="text text-danger">*</span></label>
                                    <select title="Select Program" name="program_id" id='program_id'
                                            className="form-control" defaultValue={props.dataForm.program_id}>
                                        {
                                            programList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <label>Classroom<span className="text text-danger">*</span></label>
                                    <select title="Start Section" name="classroom_id" id='classroom_id'
                                            className="form-control" defaultValue={props.dataForm.classroom_id}>
                                        <option value=""></option>
                                        {
                                            classRoomList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <label>Start Date<span className="text text-danger">*</span></label>
                                    <Input className="form-control" type="date" id="start_date" name="start_date"
                                           defaultValue={props.dataForm.start_date} required/>
                                </FormGroup>
                            </Col>

                            <Col md="3">
                                <FormGroup>
                                    <label>Starting Time<span className="text text-danger">*</span></label>
                                    <Input className="form-control" type="time" id="start_time" name="start_time"
                                           defaultValue={props.dataForm.start_time}
                                           required/>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <label>Ending Time<span className="text text-danger">*</span></label>
                                    <Input title="End Time" className="form-control" type="time" id="end_time"
                                           name="end_time" defaultValue={props.dataForm.end_time}
                                           required/>
                                </FormGroup>

                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <label>Status Class<span className="text text-danger">*</span></label>
                                    <select className="form-control" name="status" id="status"
                                            defaultValue={props.dataForm.status}>
                                        <option value="1">Active</option>
                                        <option value="2">DeActive</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="9">
                                <FormGroup>
                                    <label>Remark<span className="text text-danger">*</span></label>
                                    <textarea className="form-control" placeholder="Remark . . ." name="remark"
                                              id="remark" defaultValue={props.dataForm.remark}></textarea>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                            Save
                        </Button>
                    </form>
                        <Teacher statusRecord={statusRecord} headers={headers} branchId={props.dataForm.branch_id}
                                 classId={props.dataForm.id} teacherList={props.teacherList}/>
                </div>
            </Modal.Body>
            <div className="modal-footer">
                <Button
                    title="Close"
                    className="btn-simple"
                    type="button"
                    variant="link"
                    onClick={() => {
                        props.setUpdateRecordStatus(false);
                        props.setShowModal(false)
                    }}>
                    Close
                </Button>

            </div>
        </Modal>
</>
)
    ;
}

export default AddClass;
