import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../../common/common";
import {Button, Col, Form, FormGroup, Modal, Row, Table, Spinner, Dropdown} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";
import {maxWidth} from "@material-ui/system";

export default function PurchaseItem(props) {
    const [yearModal, setPurchaseItemModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [dataForm, setDataForm] = React.useState({});
    const [itemList, setItemList] = React.useState([]);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
         fetchList();
    }, []);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const fetchList = async () => {
        console.log(props.purchaseId)
        const {data} = await Axios.post(mainUrl + "getPurchaseItem", {"purchase_id": props.purchaseId}, {headers});
        const lists = data;
        setItemList(lists);
        setIsLoading(true);
    };

    const addPurchaseItem = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addPurchaseItem", {
            id: e.target.item_id.value,
            purchase_id: e.target.purchase_id.value,
            item_name: e.target.item_name.value,
            quantity: e.target.quantity.value,
            unit_type: e.target.unit_type_id.value,
            unit_price: e.target.unit_price.value
        }, {headers}).then(res => {
            if (e.target.item_id.value == 0) {
                notifySuccess(' Added Successfully.');
                itemList.unshift(res.data);
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setPurchaseItemModal(false);
        }).catch(function (error) {
            notifyError(error);
            setPurchaseItemModal(false);
        });
    };

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    return (
        <>
            <link crossOrigin="use-credentials"/>
            <Row>
                {/*<Col md="3"></Col>*/}
                <Col className="pr-3" md="12">
                    {isLoading === false ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="info"/>
                        </div>
                    ) : (
                        <div>
                            <AddItem unitTypeList={props.unitTypeList} purchaseId={props.purchaseId} dataForm={dataForm} setDataForm={setDataForm} />
                            <TableData setItemList={setItemList}  setDataForm={setDataForm} itemList={itemList}/>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );

    function DeleteItem() {
        const onDeleteRecord = async (e) => {
            e.preventDefault();
            await Axios.post(mainUrl + "deletePurchaseItem", {
                id: recordId,
                purchase_id: props.purchaseId,
            }, {headers}).then(res => {
                notifySuccess("Successfully deleted");
                const lists = res.data;
                setItemList(lists);
            }).catch((error) => {
                notifyError(error.response.data.message);

            });
            setRecordId(0);
            setShowDeleteModal(false);
        }

        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddItem(prop) {

        return (
            <>
                <Modal show={yearModal} backdrop="static" size="lg" style={{marginTop: "100px"}}
                       onHide={() => setPurchaseItemModal(false)}>
                    <Form onSubmit={(e) => addPurchaseItem(e)}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                                New PurchaseItem
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Input  name="purchase_id" id="purchase_id" defaultValue={prop.purchaseId}
                                   type="hidden"/>
                            <Input  name="item_id" id="item_id"  defaultValue={prop.dataForm.id}
                                   type="hidden"/>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Item Name</label>
                                        <Input required name="item_name" id="item_name" placeholder="Item Name" defaultValue={prop.dataForm.item_name}
                                               type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Quantity</label>
                                        <Input required name="quantity" id="quantity" placeholder="Quantity" defaultValue={prop.dataForm.quantity}
                                               type="Number"/>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Unit Price</label>
                                        <Input required name="unit_price" id="unit_price" placeholder="Unit Price" defaultValue={prop.dataForm.unit_price}
                                               type="Number"/>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label> Unit Type <span className="text text-danger">*</span></label>
                                        <select title="Unit Type" name="unit_type_id" id='unit_type_id' defaultValue={prop.dataForm.unit_type_id}
                                                className="form-control" >
                                            {
                                                prop.unitTypeList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.code}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setPurchaseItemModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData(prop) {
        let no = 1;
        return (
            <>
                <DeleteItem/>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="">
                                            <span className="h4 ">Purchase Item List</span>

                                            <Button disabled={props.statusRecord} title="Add New Record"  style={{float: 'inline-end'}} className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setPurchaseItemModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Item Name</th>
                                        <th>Quantity</th>
                                        <th>Unit Price</th>
                                        <th>Total</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {prop.itemList.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "20%"}}>{e.item_name}</td>
                                            <td style={{width: "20%"}}>{e.quantity} {e.unit_name}</td>
                                            <td style={{width: "20%"}}>{e.unit_price}</td>
                                            <td style={{width: "20%"}}>{e.total}</td>
                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Update & Detail View Record"
                                                                onClick={async () => {
                                                                    await setPurchaseItemModal(true);
                                                                    prop.setDataForm(e);
                                                                }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
