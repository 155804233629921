import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../../common/common";
import {Button, Col, Form, FormGroup, Modal, Row, Table, Spinner, Dropdown} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";

export default function UnitType(headers) {
    const [yearModal, setUnitTypeModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [list, setList] = React.useState([]);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetchList();
    }, []);
    const fetchList = async () => {
        const {data} = await Axios.get(mainUrl + "getUnitType", headers);
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };
    const addUnitType = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addUnitType", {
            id: e.target.unit_id.value,
            name: e.target.unit_code.value,
            description: e.target.unit_description.value
        }, headers).then(res => {
            if (e.target.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setUnitTypeModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setUnitTypeModal(false);
        });
    };


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    return (
        <>

            <Row>
                <Col md="3"></Col>
                <Col className="pr-3" md="6">
                    {isLoading === false ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="info"/>
                        </div>
                    ) : (
                        <div>
                            <AddItem/>
                            <TableData/>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );

    function DeleteItem() {

        const onDeleteRecord = async (e) => {
            e.preventDefault();
            await Axios.post(mainUrl + "deleteUnitType", {
                id: recordId
            }, headers).then(res => {
                notifySuccess("Successfully deleted");
                const lists = res.data;
                setList(lists);
            }).catch((error) => {
                notifyError(error.response.data.message);

            });
            setRecordId(0);
            setShowDeleteModal(false);
        }

        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddItem() {
        return (
            <>
                <Modal show={yearModal} backdrop="static" size="lg" onHide={() => setUnitTypeModal(false)}>
                    <Form onSubmit={(e) => addUnitType(e)}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                                New Unit Type
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Input hidden={true} required name="unit_id" id="unit_id" value="0" type="hidden"/>

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>UnitType <span className="text text-danger">*</span></label>
                                        <Input required name="unit_code" id="unit_code"
                                               placeholder="UnitType name" title="UnitType name" type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label>Description <span className="text text-danger">*</span></label>
                                        <textarea className="form-control" placeholder="description . . ."
                                                  name="unit_description" id="unit_description"></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setUnitTypeModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <DeleteItem/>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">

                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setUnitTypeModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>UnitType Name</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "100%"}}>{e.code}</td>

                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Update & Detail View Record"
                                                                onClick={async () => {
                                                                    await setUnitTypeModal(true);
                                                                    document.getElementById('unit_code').value = e.code;
                                                                    document.getElementById('unit_id').value = e.id;
                                                                    document.getElementById('unit_description').value = e.description;
                                                                }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
