import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import {Route, Switch, useLocation} from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import Login from "../views/SignIn/Login";
import {Redirect} from "react-router";

var ps;
function Dashboard(props) {
    const mainPanel = React.useRef();
    const location = useLocation();
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        };
    });
    React.useEffect(() => {
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [location]);
    if (sessionStorage.getItem("token") == null) {
        return (
            <div className="wrapper">
                <div className="" ref={mainPanel}>
                    <Login/>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="wrapper">
                <div className="main-panel" style={{position: 'absolute',width: '100%',}} ref={mainPanel}>
                    <DemoNavbar {...props} routes={routes} />
                    <Switch>

                        {routes.map((prop, key) => {
                            return (
                                <Route
                                    path={prop.layout + prop.path}
                                    component={prop.component}
                                    key={key}
                                />
                            );
                        })}
                        <Redirect from="/" to="/dashboard" />
                    </Switch>
                    <Footer fluid/>
                </div>
            </div>
        );
    }
}

export default Dashboard;
