import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../../common/common";
import {Button, Col, Form, FormGroup, Modal, Row, Table, Spinner, Dropdown} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";
import {maxWidth} from "@material-ui/system";

export default function AccountItem(props) {
    const [yearModal, setAccountItemModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [dataForm, setDataForm] = React.useState({});
    const [itemList, setItemList] = React.useState([]);
    const [elementList, setElementList] = React.useState([]);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
         fetchList();
         fetchElementList();
    }, [props.coaId]);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const fetchList = async () => {
        const {data} = await Axios.post(mainUrl + "getPayment", {"invoice_id": props.accountId}, {headers});
        const lists = data;
        setItemList(lists);
        setIsLoading(true);
    };
    const fetchElementList = async () => {
        const {data} = await Axios.post(mainUrl + "getCoaElement", {"coa_id": props.coaId}, {headers});
        const lists = data;
        setElementList(lists);
    };

    const addAccountItem = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addAccountItem", {
            id: e.target.item_id.value,
            invoice_id: props.accountId,
            coa_id: props.coaId,
            element_id: e.target.element_id.value,
            credit: e.target.item_credit.value,
            debit: e.target.item_debit.value,
            description: e.target.item_description.value,

        }, {headers}).then(res => {
            if (e.target.item_id.value == 0) {
                notifySuccess(' Added Successfully.');
                itemList.unshift(res.data);
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setAccountItemModal(false);
        }).catch(function (error) {
            notifyError(error);
            setAccountItemModal(false);
        });
    };

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    return (
        <>
            <link crossOrigin="use-credentials"/>
            <Row>
                {/*<Col md="3"></Col>*/}
                <Col className="pr-3" md="12">
                    {isLoading === false ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="info"/>
                        </div>
                    ) : (
                        <div>
                            <AddItem unitTypeList={props.unitTypeList} AccountId={props.AccountId} dataForm={dataForm} setDataForm={setDataForm} />
                            <TableData setItemList={setItemList}  setDataForm={setDataForm} itemList={itemList}/>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );


    function DeleteItem() {
        const onDeleteRecord = async (e) => {
            e.preventDefault();
            await Axios.post(mainUrl + "deleteAccountItem", {
                id: recordId,
                Account_id: props.AccountId,
            }, {headers}).then(res => {
                notifySuccess("Successfully deleted");
                const lists = res.data;
                setItemList(lists);
            }).catch((error) => {
                notifyError(error.response.data.message);

            });
            setRecordId(0);
            setShowDeleteModal(false);
        }

        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddItem(prop) {

        return (
            <>
                <Modal show={yearModal} backdrop="static" size="lg" style={{marginTop: "100px"}}
                       onHide={() => setAccountItemModal(false)}>
                    <Form onSubmit={(e) => addAccountItem(e)}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                                New Item
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Input  name="invoice_id" id="invoice_id" defaultValue={prop.AccountId}
                                   type="hidden"/>
                            <Input  name="item_id" id="item_id"  defaultValue={prop.dataForm.id}
                                   type="hidden"/>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label>Element Type</label>
                                        <select title="Select Element" name="element_id" id='element_id'
                                                className="form-control" defaultValue={prop.dataForm.element_id}>
                                            {
                                                elementList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Debit</label>
                                        <Input required name="item_debit" id="item_debit" placeholder="Debit"
                                               onKeyPress={()=>document.getElementById('item_credit').value = 0}
                                               defaultValue={prop.dataForm.debit}
                                               type="Number"/>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Credit</label>
                                        <Input required name="item_credit" id="item_credit" placeholder="Credit"
                                               onKeyPress={()=>document.getElementById('item_debit').value = 0}
                                               defaultValue={prop.dataForm.credit}
                                               type="Number"/>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Description</label>
                                        <textarea className="form-control" placeholder="Description" name="item_description"
                                                  id="item_description" defaultValue={prop.dataForm.description}></textarea>
                                    </FormGroup>
                                </Col>

                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setAccountItemModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData(prop) {
        let no = 1;
        return (
            <>
                <DeleteItem/>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="">
                                            <span className="h4 ">Account Item List</span>

                                            <Button disabled={props.statusRecord} title="Add New Record"  style={{float: 'inline-end'}} className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setAccountItemModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Element Type</th>
                                        <th>Credit</th>
                                        <th>Debit</th>
                                        <th>Description</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {prop.itemList.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "20%"}}>{e.element_name}</td>
                                            <td style={{width: "20%"}}>{e.credit}</td>
                                            <td style={{width: "20%"}}>{e.debit}</td>
                                            <td style={{width: "20%"}}>{e.description}</td>
                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Update & Detail View Record"
                                                                onClick={async () => {
                                                                    await setAccountItemModal(true);
                                                                    prop.setDataForm(e);
                                                                }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
