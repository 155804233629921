import React, {useEffect, useState, useRef} from "react";
import ImageSelect from "./utils/imageSelect";
import {Button, Col, FormGroup, Modal, Row, Spinner} from "react-bootstrap";
import image from "../../assets/img/image.png";
import {Card, Input} from "reactstrap";
import Axios from "axios";
import {mainUrl, toBase64, dataURLtoFile} from "../../common/common.js";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import "./style.css";
import CurrencyInput from 'react-currency-input-field';

// import {useRef} from "preact";

function AddStudent(props) {

    const [showUploadImage, setShowUploadImage] = useState(false);
    const [calculate, setCalculate] = useState({
        "total": 0,
        "monthly_fee": 0,
        "material_fee": 0,
        "exam_fee": 0,
        "admission_fee": 0
    });
    const [formStep, setFormStep] = useState(0);
    const [downloadStatus, setDownloadStatus] = useState(false);

    const [programList, setProgramList] = useState([]);
    const [classList, setClassList] = useState([]);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addStudent = async (e) => {
        e.preventDefault();
        const f = e.target;

        let attachFile = "";
        if (f.attachment.files[0] != null && f.attachment.files[0] != "") {
            attachFile = await toBase64(f.attachment.files[0]);
        }
        await Axios.post(mainUrl + "addStudent", {
            id: f.ids.value,
            payment: f.payment.value,
            amount: f.total.value,
            discount: f.percentage.value,
            monthly_fee: f.monthly_fee.value * (100 - f.percentage.value) / 100,
            material_fee: f.material_fee.value * (100 - f.percentage.value) / 100,
            exam_fee: f.exam_fee.value * (100 - f.percentage.value) / 100,
            admission_fee: f.admission_fee.value * (100 - f.percentage.value) / 100,
            description: f.description.value,
            due_date: f.due_date.value,
            class_date: f.class_date.value,
            title: f.title.value,
            class_id: f.class_id.value,
            program_id: f.program_id.value,
            department_id: f.department_id.value,
            branch_id: f.branch_id.value,
            remark: f.remark.value,
            attachment: f.attachment.value,
            job: f.job.value,
            year_id: f.year_id.value,
            dob: f.dob.value,
            gender_id: f.gender_id.value,
            current_address: f.current_address.value,
            main_address: f.main_address.value,
            phone: f.phone.value,
            email: f.email.value,
            fname: f.fname.value,
            name: f.name.value
        }, {headers}).then(res => {
            if (f.ids.value == 0) {
                props.notifySuccess(' Added Successfully.');
            } else {
                props.notifySuccess(' Updated Successfully.');
            }
            document.querySelector(".main-panel")
                .scrollTo(0, 0);
            props.setList(res.data);
            setFormStep(formStep + 1);
            // props.setShowModal(false);
            props.setUpdateRecordStatus(false);
        }).catch(function (error) {
            props.notifyError(error.response.data.message);
        });
    };

    const onDepartmentChange = async (e) => {
        e.preventDefault();
        let newArray = props.programList.filter(function (el) {
            return el.department_id == e.target.value;
        }).map(a => a.id);
        let res = props.programList.filter(function (el) {
            return newArray.includes(el.id);
        })
        setProgramList(res);
        reset();
        document.getElementById('program_id').value = "";
        document.getElementById('class_id').value = "";
        setClassList([]);
        reset();


    };
    const onProgramChange = async (e) => {
        e.preventDefault();
        let newArray = props.classList.filter(function (el) {
            return el.program_id == e.target.value;
        }).map(a => a.id);
        let res = props.classList.filter(function (el) {
            return newArray.includes(el.id);
        })
        setClassList(res);
        document.getElementById('class_id').value = "";
    };

    const downloadFile = async () => {
        setDownloadStatus(true);
        const f = document.getElementById('ids').value;
        await Axios.post(mainUrl + "getAttachFile", {
            employee_id: f
        }, {headers}).then(res => {
            var filePDf = dataURLtoFile('data:application/x-zip-compressed;base64,' + res.data, 'attachment.zip');
            const previewUrl = window.URL.createObjectURL(filePDf);
            const anchor = document.createElement("a");
            anchor.download = "attachment.zip";
            anchor.href = URL.createObjectURL(filePDf);

            anchor.click();
            window.URL.revokeObjectURL(previewUrl);
        }).catch(function (error) {
            props.notifyError(error.response.data.message);
        });
        setDownloadStatus(false);
    }

    const reset = () => {
        document.getElementById('admission_fee').value = 0;
        document.getElementById('monthly_fee').value = 0;
        document.getElementById('material_fee').value = 0;
        document.getElementById('exam_fee').value = 0;
        document.getElementById('percentage').value = 0;
        document.getElementById('total').value = 0;
        document.getElementById('payment').value = 0;
    }
    const onCalculate = async (e) => {
        e.preventDefault();
        const data = e.target.options[e.target.selectedIndex];
        await setCalculate({
            "payment": '0',
            "total": '0',
            "monthly_fee": data.getAttribute('monthly_fee'),
            "material_fee": data.getAttribute('material_fee'),
            "exam_fee": data.getAttribute('examination_fee'),
            "admission_fee": data.getAttribute('admission_fee')
        });
        document.getElementById('admission_fee').value = data.getAttribute('admission_fee');
        document.getElementById('monthly_fee').value = data.getAttribute('monthly_fee');
        document.getElementById('material_fee').value = data.getAttribute('material_fee');
        document.getElementById('exam_fee').value = data.getAttribute('examination_fee');
        document.getElementById('percentage').value = 0;
        await calculateTotal();
    }

    const calculateTotal = () => {
        const total = (
            parseInt(document.getElementById('admission_fee').value)
            + parseInt(document.getElementById('monthly_fee').value)
            + parseInt(document.getElementById('material_fee').value)
            + parseInt(document.getElementById('exam_fee').value))
            * (100 - parseInt(document.getElementById('percentage').value));
        document.getElementById('total').value = total / 100;
        document.getElementById('payment').value = total / 100;
    }


    const convertTime24to12 = (time24h) => {
        let time = time24h
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time24h];

        if (time.length > 1) {
            time = time.slice(1, -1);
            time[5] = +time[0] < 12 ? ' AM' : ' PM';
            time[0] = +time[0] % 12 || 12;
        }
        return time.join('');
    };

    const returnValue = (v?, att?) => {
        if (document.getElementById(v)) {
            if (att != null) {
                const el = document.getElementById(v);
                const data = el.options[el.selectedIndex];
                return data.getAttribute(att);
            } else {
                return document.getElementById(v).value;
            }
        } else {
            return "";
        }
    }
    return (
        <>
            {showUploadImage == true && <ImageSelect setLocalImageStudent={props.setLocalImageStudent}/>}
            <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
                <form onSubmit={(e) => addStudent(e)}>
                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile">
                            New Student {formStep}
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={formStep === 0 ? "" : "visually-hidden"}>
                            <div className="text-center">
                                <div className=" btn"
                                     style={{position: "relative", textAlign: "center", color: "white"}} alt="Image "
                                     title="Image">
                                    <img src={props.localImageStudent != "" ? props.localImageStudent : image}
                                         style={{borderRadius: "50%", backgroundColor: "#8080805e!important"}}
                                         onClick={() => {
                                             if (!showUploadImage) {
                                                 setShowUploadImage(true);
                                             } else {
                                                 setShowUploadImage(false);
                                             }
                                         }} width="125" height="125"/>
                                    <div style={{
                                        position: "absolute",
                                        top: "89%",
                                        left: "50%",
                                        transform: "translate(-50%, 50%)",
                                        whiteSpace: "nowrap"
                                    }} className="text-primary">Select Image
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="image" id="image" value={props.localImageStudent}/>
                        <div style={{height: '500px', overflowY: 'auto', marginTop: '20px', paddingBottom: '50px   '}}>
                            <div className={formStep === 0 ? "" : "visually-hidden"}>
                                <Row className="pr-3">
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Student ID</label>
                                            <Input type="hidden" name="ids" id="ids"/>
                                            <Input name="student_code" id="student_code"
                                                   placeholder="Student Code" type="text"/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-3 " md="6">
                                        <FormGroup>
                                            <label>Name <span className="text text-danger">*</span></label>
                                            <Input required name="name" id="name" required placeholder="name"
                                                   title="name" type="text"/>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="6">
                                        <FormGroup>
                                            <label>Father Name <span className="text text-danger">*</span></label>
                                            <Input required name="fname" id="fname"
                                                   placeholder="Father Name" title="Father Name"
                                                   type="text"/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-3" md="6">
                                        <FormGroup>
                                            <label> Email address <span
                                                className="text text-danger">*</span></label>
                                            <Input required name="email" id="email" placeholder="Email Address"
                                                   type="email" title="Email Address"/>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="6">
                                        <FormGroup>
                                            <label> Phone Number <span className="text text-danger">*</span></label>
                                            <Input type="tel"
                                                   required
                                                   title="Phone Number"
                                                   name="phone" id="phone"
                                                   placeholder="Phone Number"
                                                   pattern="[1-9]{1}[0-9]{8}"/>
                                            <small className="badge badge-default"> Format: 700123456 </small>
                                            <br/>
                                        </FormGroup>
                                    </Col>

                                    <Col className="pr-3" md="6">
                                        <FormGroup>
                                            <label>Mine Residence Address <span
                                                className="text text-danger">*</span></label>
                                            <Input type="text" required name="main_address" id="main_address" required
                                                   className="form-control" title="Mine Residence Address"
                                                   placeholder="Mine Residence Address"/>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="6">
                                        <FormGroup>
                                            <label>Current Residence Address <span className="text text-danger">*</span></label>
                                            <Input type="text" required name="current_address" id="current_address"
                                                   required
                                                   className="form-control" title="Current Residence Address"
                                                   placeholder="Current Residence Address"/>
                                        </FormGroup>
                                    </Col>

                                    <Col className="pr-3" md="6">
                                        <FormGroup>
                                            <label>Gender <span className="text text-danger">*</span></label>
                                            <select required name="gender_id" id="gender_id" required
                                                    className="form-control" title="Gender"
                                                    placeholder="Select Gender Type">
                                                <option value=""></option>
                                                <option value="1" code="Male">Male</option>
                                                <option value="2" code="Female">Female</option>
                                            </select>

                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="6">
                                        <FormGroup>
                                            <label>Date of birth <span className="text text-danger">*</span></label>
                                            <Input type="date" required name="dob" id="dob" required
                                                   className="form-control" title="Date of birth"/>
                                        </FormGroup>
                                    </Col>

                                    <Col className="pr-3" md="6">
                                        <FormGroup>
                                            <label>Job <span className="text text-danger">*</span></label>
                                            <select required name="job" id='job'
                                                    className="form-control" title="Select Job type">
                                                <option value=""></option>
                                                <option value="1" code="School Student">School Student</option>
                                                <option value="2" code="University Student">University Student</option>
                                                <option value="3" code="Employee">Employee</option>
                                                <option value="4" code="Unemployed">Unemployed</option>
                                            </select>
                                        </FormGroup>
                                    </Col>


                                    <Col className="pr-3" md="6">
                                        <FormGroup>
                                            <label>Attachment <span className="text text-danger">*</span></label>
                                            <input name="attachment" id='attachment' accept=".zip" type="file"
                                                   title="Attach File"
                                                   className="form-control"/>
                                            Select pdf file only.<br/>
                                            Maximum upload file size :5 MB
                                        </FormGroup>
                                        {props.attachFile == true &&
                                        <button className="btn btn-warning" onClick={() => downloadFile()}>
                                            {downloadStatus === true ? ("Loading . . . ") : ("Download File")}
                                        </button>
                                        }
                                    </Col>
                                    <Col className="pr-3" md="6">
                                        <FormGroup>
                                            <label>Remark <span className="text text-danger">*</span></label>
                                            <textarea className="form-control" placeholder="Remark . . ." title="Remark"
                                                      name="remark" id="remark"></textarea>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <div className={(formStep === 1) ? "" : "visually-hidden"}>
                                <div className="col-md-12" style={{display: 'flex'}}>
                                    <div className="col-md-6">

                                        <div className="pr-3" className="col-md-12">
                                            <FormGroup>
                                                <label>Branch</label>
                                                <select title="Select Program" name="branch_id" id='branch_id'
                                                        className="form-control">
                                                    <option value=""></option>
                                                    {
                                                        props.branchList.map((e, index) => (
                                                            <option key={index}
                                                                    value={e.id} code={e.name}>{e.name}</option>))
                                                    }
                                                </select>
                                            </FormGroup>
                                        </div>
                                        <div className="pr-3" className="col-md-12">
                                            <FormGroup>
                                                <label>Department</label>
                                                <select title="Select Program" name="department_id"
                                                        className="form-control"
                                                        id='department_id'
                                                        onChange={e => onDepartmentChange(e)}
                                                        className="form-control">
                                                    <option value=""></option>
                                                    {
                                                        props.departmentList.map((e, index) => (
                                                            <option key={index}
                                                                    value={e.id} code={e.name}>{e.name}</option>))
                                                    }
                                                </select>
                                            </FormGroup>
                                        </div>
                                        <div className="pr-3" className="col-md-12">
                                            <FormGroup>
                                                <label>Program</label>
                                                <select title="Select Program" name="program_id" id='program_id'
                                                        onChange={(p) => {
                                                            onCalculate(p);
                                                            onProgramChange(p);
                                                        }}
                                                        className="form-control">
                                                    <option value=""></option>
                                                    {
                                                        programList.map((e, index) => (
                                                            <option key={index}
                                                                    monthly_fee={e.monthly_fee}
                                                                    material_fee={e.material_fee}
                                                                    examination_fee={e.examination_fee}
                                                                    admission_fee={e.admission_fee}
                                                                    value={e.id}
                                                                    code={e.name}>
                                                                {e.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </FormGroup>
                                        </div>
                                        <div className="pr-3" className="col-md-12">
                                            <FormGroup>
                                                <label>Class</label>
                                                <select title="Select Program" name="class_id" id='class_id'
                                                        className="form-control">
                                                    <option value=""></option>
                                                    {
                                                        classList.map((e, index) => (
                                                            <option key={index}
                                                                    value={e.id}

                                                                    start_time={e.start_time}
                                                                    end_time={e.end_time}
                                                                    code={e.class_code}>{e.class_code}</option>))
                                                    }
                                                </select>
                                            </FormGroup>
                                        </div>
                                        <div className="pr-3" className="col-md-12">
                                            <FormGroup>
                                                <label>Title</label>
                                                <Input required name="title" id="title"
                                                       placeholder="Title" type="text"/>
                                            </FormGroup>
                                        </div>
                                        <div className="pr-3" className="col-md-12">
                                            <FormGroup>
                                                <label>Date</label>
                                                <Input required name="class_date" id="class_date"
                                                       placeholder="Date" type="date"/>
                                            </FormGroup>
                                        </div>
                                        <div className="pr-3" className="col-md-12">
                                            <FormGroup>
                                                <label>Due Date</label>
                                                <Input required name="due_date" id="due_date"
                                                       placeholder="Due Date" type="date"/>
                                            </FormGroup>
                                        </div>
                                        <div className="pr-3" className="col-md-12">
                                            <FormGroup>
                                                <label>Description</label>
                                                <textarea name="description" id="description"
                                                          className="form-control"></textarea>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className=" col-md-1"></div>
                                    {/*Program detail*/}
                                    <div className=" col-md-5" key="12">
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <label>Year</label>
                                                <select title="Select Year" name="year_id" id='year_id'
                                                        className="form-control">
                                                    {
                                                        props.yearList.map((e, index) => (
                                                            <option key={index}
                                                                    value={e.id} code={e.name}>{e.name}</option>))
                                                    }
                                                </select>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <label>Admission Fee</label>
                                                <Input required name="admission_fee" id="admission_fee"
                                                       placeholder="Admission Fee" type="number"
                                                       onChange={async (e) => {
                                                           await setCalculate({
                                                               ...calculate,
                                                               "admission_fee": e.target.value
                                                           });
                                                           await calculateTotal();
                                                       }}
                                                       defaultValue={calculate.admission_fee || ''}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-md-12">
                                            <FormGroup>
                                                <label>Exam Fee</label>
                                                <Input required name="exam_fee" id="exam_fee"
                                                       placeholder="Exam Fee" type="number"
                                                       onChange={async (e) => {
                                                           await setCalculate({
                                                               ...calculate,
                                                               "exam_fee": e.target.value
                                                           });
                                                           await calculateTotal();
                                                       }}
                                                       defaultValue={calculate.exam_fee || ''}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <label>Material Fee</label>
                                                <Input required name="material_fee" id="material_fee"
                                                       placeholder="Material Fee" type="number"
                                                       onChange={async (e) => {
                                                           await setCalculate({
                                                               ...calculate,
                                                               "material_fee": e.target.value
                                                           });
                                                           await calculateTotal();
                                                       }}
                                                       defaultValue={calculate.material_fee || ''}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <label>Monthly Fee</label>
                                                <Input required name="monthly_fee" id="monthly_fee"
                                                       placeholder="Monthly Fee" type="number"
                                                       onChange={async (e) => {
                                                           await setCalculate({
                                                               ...calculate,
                                                               "monthly_fee": e.target.value
                                                           });
                                                           await calculateTotal();

                                                       }}
                                                       defaultValue={calculate.monthly_fee || ''}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <label>Discount Percentage</label>
                                                <Input required name="percentage" id="percentage"
                                                       placeholder="Percentage" type="number"
                                                       onChange={async (e) => {
                                                           await calculateTotal();
                                                       }}
                                                       defaultValue="0"/>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <label>Total</label>
                                                <Input required name="total" id="total"
                                                       placeholder="Total" type="text" disabled
                                                       defaultValue={calculate.total}/>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <label>Payment</label>
                                                <Input required name="payment" id="payment"
                                                       pattern="[0-9]+([,\.][0-9]+)?"
                                                       placeholder="payment" type="text" defaultValue="0"/>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={(formStep === 2) ? "" : "visually-hidden"}>
                                <div className="col-md-12" style={{display: "flex"}}>
                                    <div className="col-md-5 ">
                                        <table border="1" className="table table-bordered  ">
                                            <tr>
                                                <td colSpan="2" style={{textAlign: "center"}}>
                                                    <img
                                                        src={props.localImageStudent != "" ? props.localImageStudent : image}
                                                        style={{
                                                            borderRadius: "50%",
                                                            backgroundColor: "#8080805e!important"
                                                        }}
                                                        onClick={() => {
                                                            if (!showUploadImage) {
                                                                setShowUploadImage(true);
                                                            } else {
                                                                setShowUploadImage(false);
                                                            }
                                                        }} width="125" height="125"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Name</th>
                                                <td>{returnValue('name', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Father name</th>
                                                <td>{returnValue('fname', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Email Address</th>
                                                <td>{returnValue('email', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Phone Number</th>
                                                <td>{returnValue('phone', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Gender</th>
                                                <td>{returnValue('gender_id', 'code')}</td>
                                            </tr>
                                            <tr>
                                                <th>Job</th>
                                                <td>{returnValue('job', 'code')}</td>
                                            </tr>
                                            <tr>
                                                <th>Date of Birth</th>
                                                <td>{returnValue('dbo', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Mine Residence Address</th>
                                                <td>{returnValue('main_address', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Current Residence Address</th>
                                                <td>{returnValue('current_address', null)}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-6">
                                        <table className="table table-bordered">
                                            <tr>
                                                <th>Branch</th>
                                                <td>{returnValue('branch_id', 'code')}</td>
                                            </tr>
                                            <tr>
                                                <th>Department</th>
                                                <td>{returnValue('department_id', 'code')}</td>
                                            </tr>
                                            <tr>
                                                <th>Program</th>
                                                <td>{returnValue('program_id', 'code')}</td>
                                            </tr>
                                            <tr>
                                                <th>Class</th>
                                                <td>{returnValue('class_id', 'code')}</td>
                                            </tr>
                                            <tr>
                                                <th>Title</th>
                                                <td>{returnValue('title', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Date</th>
                                                <td>{returnValue('class_date', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Due Date</th>
                                                <td>{returnValue('due_date', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Description</th>
                                                <td>{returnValue('description', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Admission Fee</th>
                                                <td>{returnValue('admission_fee', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Exam Fee</th>
                                                <td>{returnValue('exam_fee', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Material Fee</th>
                                                <td>{returnValue('material_fee', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Monthly Fee</th>
                                                <td>{returnValue('monthly_fee', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Discount Percentage</th>
                                                <td>{returnValue('percentage', null)} %</td>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                <td>{returnValue('total', null)}</td>
                                            </tr>
                                            <tr>
                                                <th>Payment</th>
                                                <td>{returnValue('payment', null)}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={(formStep === 1) ? "" : "visually-hidden"}>
                            <div className="row" style={{color: "#3AB2F4"}}>
                                <div className="col-md-12" style={{
                                    textAlign: "center",
                                    border: "0px red solid",
                                    borderBottom: "5px #3AB2F4 solid",
                                    height: "55px"
                                }}>
                                    Log
                                </div>
                                <div className="col-md-12"><br/></div>
                                <div className="col-md-12" style={{display: "flex"}}>

                                    <div className="col-md-1"></div>
                                    <div className=" col-md-4">
                                        <table className="table recept">
                                            <tbody>
                                            <tr>
                                                <td style={{width: "20%"}}>Name</td>
                                                <td style={{
                                                    width: "80%",
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}> {returnValue('name', null)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>F/Name</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}> {returnValue('fname', null)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Department</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}> {returnValue('department_id', 'code')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Program</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}>  {returnValue('program_id', 'code')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Class</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}>  {returnValue('class_id', 'code')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Time</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}>  {convertTime24to12(returnValue('class_id', 'start_time')!=null?returnValue('class_id', 'start_time'):"00:00:00")} -
                                                    {convertTime24to12(returnValue('class_id', 'end_time')!=null?returnValue('class_id', 'end_time'):"00:00:00")}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4">
                                        <table className="table recept">
                                            <tbody>
                                            <tr>
                                                <td style={{width:'20%'}}>Admission Fee</td>
                                                <td style={{
                                                    width:'80%',
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}>  {returnValue('admission_fee', null)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Monthly Fee</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}>  {returnValue('monthly_fee', null)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Examination Fee</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}> {returnValue('exam_fee', null)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>R.Material Fee</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}> {returnValue('material_fee', null)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Discount Percentage</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}> {returnValue('percentage', null)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Total Fee</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}> {returnValue('total', null)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Paid Fee</td>
                                                <td style={{
                                                    border: "0px red solid",
                                                    borderBottom: "2px #3AB2F4 dashed"
                                                }}> {returnValue('payment', null)}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                            title="Close"
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => {
                                props.setUpdateRecordStatus(false);
                                props.setShowModal(false)
                            }}>
                            Close
                        </Button>
                        {formStep <= 3 &&
                        <Button className="btn btn-" onClick={() => {
                            if (formStep < 4) {
                                setFormStep(formStep - 1)
                            }
                        }}>Back</Button>
                        }
                        {formStep < 2 &&
                        <Button className="btn btn-" onClick={() => setFormStep(formStep + 1)}>Next</Button>
                        }
                        {formStep == 2 &&
                        <Button className="btn-round" title="Insert & update  Record" color="danger"
                                type="submit">Save</Button>
                        }
                        {formStep > 3 &&
                        <Button className="btn-round" color="danger" type="submit">
                            Print
                        </Button>
                        }
                    </div>
                </form>
            </Modal>
        </>
    );
}

export default AddStudent;
