import "./../../assets/css/bootstrap.min.css"
import React, {useEffect, useState} from "react";
import {Col,Row} from "react-bootstrap";
import {CardHeader, CardTitle, Input, Card} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import toast, {Toaster} from 'react-hot-toast';
import AddClass from "./AddClass";
import ListClass from "./ListClass";
import SearchClass from "./SearchClass";

function Class() {


    const [subAdminStatus, setSubAdminStatus] = useState(1);

    const [classList, setClassList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [classRoomList, setClassRoomList] = useState([]);
    const [teacherList, setTeacherList] = useState([]);

    const [list, setList] = React.useState([]);
    const [dataForm, setDataForm] = React.useState([]);
    const [attachFile, setAttachFiles] = React.useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [localImageClass, setLocalImageClass] = useState("");

    const [updateRecordStatus, setUpdateRecordStatus] = React.useState(false);
    const [needObject, setNeedObject] = React.useState({'project_id':0,'province_id':0,'branch_id':0,'class_id':0});
    const [searchParameter, setSearchParameter] = React.useState({})
    const [searchStatus, setSearchStatus] = React.useState(false);


    useEffect(() => {
        loadData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    function getBranchs() {
        return Axios.get(mainUrl + "getBranch",{headers});
    }
    function getClasss() {
        return Axios.get(mainUrl + "getClass?page=1",{headers});
    }
    function getDepartment() {
        return Axios.get(mainUrl + "getDepartment",{headers});
    }
    function getYear() {
        return Axios.get(mainUrl + "getYear",{headers});
    }
    function getClassRoom() {
        return Axios.get(mainUrl + "getAllClassRoom",{headers});
    }
    function getProgram() {
        return Axios.get(mainUrl + "getProgram",{headers});
    }
    function getTeacherList() {
        return Axios.get(mainUrl + "getTeacherList",{headers});
    }


    const loadData= async()=>{
        await Promise.all([getClasss()])
            .then(function (results) {
                setList(results[0].data);
                setIsLoading(true);
                loadParameters();
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });;
    }

    const loadParameters= async()=>{
        await Promise.all([getTeacherList(),getProgram(),getClassRoom(),getBranchs(),getDepartment(),getYear()])
            .then(function (results) {
                setTeacherList(results[0].data);
                setProgramList(results[1].data);
                setClassRoomList(results[2].data);

                setBranchList(results[3].data);
                setDepartmentList(results[4].data);
                setYearList(results[5].data);

            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });;
    }

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">
                <div>
                    <Row>
                        <Col md="12">
                            <Card className="card-user">
                                <CardHeader style={{backgroundColor: "#80808029"}}>
                                    <CardTitle className="mr-4 ml-4">
                                        <div className="row">
                                            <div className="ml-right">
                                                <h3>Class Management</h3>
                                            </div>
                                        </div>
                                    </CardTitle>
                                </CardHeader>
                                <SearchClass yearList={yearList} departmentList={departmentList} programList={programList} searchStatus={searchStatus} setSearchStatus={setSearchStatus} searchParameter={searchParameter} setSearchParameter={setSearchParameter} setLocalImageClass={setLocalImageClass} localImageClass={localImageClass} isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal} setShowModal={setShowModal} setList={setList} branchList={branchList}
                                              headers={headers} notifyError={notifyError} notifySuccess={notifySuccess}/>
                            </Card>
                        </Col>
                    </Row>
                    <AddClass  teacherList={teacherList} setDataForm={setDataForm}  dataForm={dataForm}  classRoomList={classRoomList}  yearList={yearList} departmentList={departmentList} programList={programList} setNeedObject={setNeedObject} needObject={needObject} setUpdateRecordStatus={setUpdateRecordStatus} updateRecordStatus={updateRecordStatus} subAdminStatus={subAdminStatus} setSubAdminStatus={setSubAdminStatus} setAttachFiles={setAttachFiles} attachFile={attachFile} setClassList={setClassList} classList={classList}  setLocalImageClass={setLocalImageClass} localImageClass={localImageClass} isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal} setShowModal={setShowModal} setList={setList} list={list}  branchList={branchList}
                                 headers={headers} notifyError={notifyError} notifySuccess={notifySuccess}/>
                    <ListClass setDataForm={setDataForm} searchStatus={searchStatus} setSearchStatus={setSearchStatus} searchParameter={searchParameter} setSearchParameter={setSearchParameter} setNeedObject={setNeedObject} needObject={needObject} setUpdateRecordStatus={setUpdateRecordStatus} updateRecordStatus={updateRecordStatus} subAdminStatus={subAdminStatus} setSubAdminStatus={setSubAdminStatus} setAttachFiles={setAttachFiles} attachFile={attachFile}  setLocalImageClass={setLocalImageClass} localImageClass={localImageClass} isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal} setShowModal={setShowModal} setList={setList} list={list}  headers={headers} notifyError={notifyError}
                                 notifySuccess={notifySuccess}/>
                </div>
            </div>
        </>
    );


}


export default Class;
