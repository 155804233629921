import React, {useEffect, useState} from "react";

import {Button, Col, FormGroup, Modal, Row, Spinner} from "react-bootstrap";
import { Input} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../common/common.js";
import 'react-phone-number-input/style.css';
import AccountItem from "./AccountItem/AccountItem";


function AddAccount(props) {

    const [statusRecord, setStatusRecord] = useState(true);

    const [branchStatus, setBranchStatus] = useState(true);
    const [departmentStatus, setDepartmentStatus] = useState(true);
    const [programStatus, setProgramStatus] = useState(true);
    const [classStatus, setClassStatus] = useState(true);

    useEffect(() => {
        if (props.dataForm.id) {
            console.log('*')
            setStatusRecord(false)
        } else {
            setStatusRecord(true)
        }
    });

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addAccount = async (e) => {
        e.preventDefault();
        const f = e.target;
        await Axios.post(mainUrl + "addInvoice", {
            id: f.ids.value,
            remark:f.remark.value,
            coa_id:f.coa_id.value,
            vendor_id:f.vendor_id.value,
            employee_id:f.employee_id.value,
            student_id:f.student_id.value,
            class_id:f.class_id.value,
            program_id:f.program_id.value,
            department_id:f.department_id.value,
            branch_id:f.branch_id.value,
            year_id:f.year_id.value,
            date:f.date.value,
        }, {headers}).then(res => {
            if (f.ids.value == 0) {
                props.notifySuccess(' Added Successfully.');
               (props.list.data.unshift(res.data));
            } else {
                props.notifySuccess(' Updated Successfully.');
            }
            document.querySelector(".main-panel")
                .scrollTo(0, 0);
            props.setDataForm(res.data);
            setStatusRecord(false);
            props.setUpdateRecordStatus(false);
        }).catch(function (error) {
            console.error(error);
            props.notifyError(error);
        });
    };
    const onBranchChange = async(e)=> {
        if(e.target.value !==null){
            setBranchStatus(false);
        }else{
            setBranchStatus(true);
        }
    }
    const onDepartmentChange = async(e)=> {
        if(e.target.value !==null){
            setDepartmentStatus(false);
        }else{
            setDepartmentStatus(true);
        }
    }
    const onProgramChange = async(e)=> {
        if(e.target.value !==null){
            setProgramStatus(false);
        }else{
            setProgramStatus(true);
        }
    }
    const onClassChange = async(e)=> {
        if(e.target.value !==null){
            setClassStatus(false);
        }else{
            setClassStatus(true);
        }
    }

    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    var currentDate = startDate.toISOString().substr(0, 10);

    return (
        <>
            <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                        Add or Modify Account
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div style={{height: '500px', overflowY: 'auto', marginTop: '20px', paddingBottom: '50px   '}}>
                        <form onSubmit={(e) => addAccount(e)}>
                            <Row className="pr-3">
                                <Col md="6">
                                    <Input type="hidden" className="" name="ids" id="ids"
                                           defaultValue={props.dataForm.id}/>
                                    <FormGroup  >
                                        <label>Account Code</label>
                                        <Input   name="Account_code" id="Account_code"
                                               defaultValue={("INV-"+("0000"+props.dataForm.id).slice(-5))}
                                               placeholder="Account Code" type="text"/>

                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Date</label>
                                        <Input required name="data" id="date" defaultValue={props.dataForm.date}
                                            // defaultValue={currentDate}
                                               placeholder="date" type="date"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="pr-3">
                                <Col md="4">
                                    <FormGroup>
                                        <label> Year <span className="text text-danger">*</span></label>
                                        <select required title="Select Year" name="year_id" id='year_id'
                                                className="form-control" defaultValue={props.dataForm.year_id}>
                                            {
                                                props.yearList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label> Branch <span className="text text-danger">*</span></label>
                                        <select required title="ُSelect Branch" name="branch_id" id='branch_id'
                                                onChange={e => onBranchChange(e)}
                                                className="form-control" defaultValue={props.dataForm.branch_id}>
                                            <option value=""></option>
                                            {
                                                props.branchList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label> Department <span className="text text-danger">*</span></label>
                                        <select disabled={branchStatus} title="Select Department" name="department_id" id='department_id'
                                                onChange={e => onDepartmentChange(e)}
                                                className="form-control" defaultValue={props.dataForm.department_id}>
                                            <option value=""></option>
                                            {
                                                props.departmentList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label> Program <span className="text text-danger">*</span></label>
                                        <select disabled={departmentStatus} title="Select Program" name="program_id" id='program_id'
                                                onChange={e => onProgramChange(e)}
                                                className="form-control" defaultValue={props.dataForm.program_id}>
                                            <option value=""></option>
                                            {
                                                props.branchList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label> Class <span className="text text-danger">*</span></label>
                                        <select disabled={programStatus} title="Select Class" name="class_id" id='class_id'
                                                onChange={e => onClassChange(e)}
                                                className="form-control" defaultValue={props.dataForm.class_id}>
                                            <option value=""></option>
                                            {
                                                props.branchList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label> Student <span className="text text-danger">*</span></label>
                                        <select disabled={classStatus} title="Select Student" name="student_id" id='student_id'
                                                className="form-control" defaultValue={props.dataForm.student_id}>
                                            <option value=""></option>
                                            {
                                                props.branchList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label> Employee <span className="text text-danger">*</span></label>
                                        <select disabled={branchStatus} title="Select Employee" name="employee_id" id='employee_id'
                                                className="form-control" defaultValue={props.dataForm.employee_id}>
                                            <option value=""></option>
                                            {
                                                props.branchList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>Vendor <span className="text text-danger">*</span></label>
                                        <select disabled={branchStatus} title="Select Vendor" name="vendor_id"
                                                id='vendor_id'
                                                className="form-control" defaultValue={props.dataForm.vendor_id}>
                                            <option value=""></option>
                                            {
                                                props.vendorList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.company_name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>Chart of Account <span className="text text-danger">*</span></label>
                                        <select required disabled={branchStatus} title="Select Chart of Account" name="coa_id"
                                                id='coa_id'
                                                className="form-control" defaultValue={props.dataForm.coa_id}>
                                            <option value=""></option>
                                            {
                                                props.coaList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>Remark<span className="text text-danger">*</span></label>
                                        <textarea required className="form-control" placeholder="Remark . . ." name="remark"
                                                  id="remark" defaultValue={props.dataForm.remark}></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Save
                            </Button>
                        </form>
                        <AccountItem statusRecord={statusRecord}
                                     headers={headers}
                                     branchId={props.dataForm.branch_id}
                                     accountId={props.dataForm.id}
                                     coaId={props.dataForm.coa_id}
                                     classId={props.dataForm.id} teacherList={props.teacherList}
                                     unitTypeList={props.unitTypeList}/>
                    </div>
                </Modal.Body>
                <div className="modal-footer">
                    <Button
                        title="Close"
                        className="btn-simple"
                        type="button"
                        variant="link"
                        onClick={() => {
                            props.setUpdateRecordStatus(false);
                            props.setShowModal(false)
                        }}>
                        Close
                    </Button>

                </div>
            </Modal>
        </>
    )
        ;
}

export default AddAccount;
