import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Form, FormGroup, Modal, Row, Table, Spinner, Dropdown} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";

export default function Coa(headers) {
    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showAddElementItem, setShowAddElementItem] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [list, setList] = React.useState([]);
    const [property, setProperty] = React.useState({});

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetchList();
    }, []);
    const fetchList = async () => {
        const {data} = await Axios.get(mainUrl + "getCoa", headers);
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };
    const addCoa = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addCoa", {
            description: e.target.account_description.value,
            name: e.target.account_name.value,
            code: e.target.account_code.value,
            id: e.target.account_id.value,
        }, headers).then(res => {
            if (e.target.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setShowModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setShowModal(false);
        });
    };

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    return (
        <>

            <Row>
                <Col md="3"></Col>
                <Col className="pr-3" md="6">
                    <div className="">
                        {isLoading === false ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : (
                            <div>
                                <AddItem/>
                                <TableData/>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );

    function DeleteItem() {

        const onDeleteRecord = async (e) => {
            e.preventDefault();
            await Axios.post(mainUrl + "deleteCoa", {
                id: recordId
            }, headers).then(res => {
                notifySuccess("Successfully deleted");
                const lists = res.data;
                setList(lists);
            }).catch((error) => {
                notifyError(error.response.data.message);

            });
            setRecordId(0);
            setShowDeleteModal(false);
        }

        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddElementItem() {

        const [coaElementlist, setCoaElementlist] = React.useState([]);
        const [showAddItems, setShowAddItems] = React.useState(false);
        const [showClassItmeDeleteModal, setShowClassItmeDeleteModal] = React.useState(false);
        const [recordElementId, setRecordElementId] = React.useState(0);
        useEffect(() => {
            fetchCoaElementList();
        }, []);
        const fetchCoaElementList = async () => {
            const {data} = await Axios.post(mainUrl + "getCoaElement", {
                coa_id: property.account_id,
            }, headers);
            const lists = data;
            setCoaElementlist(lists);
        };

        function DeleteItemClass() {
            const onDeleteRecord = async (e) => {
                e.preventDefault();
                await Axios.post(mainUrl + "deleteCoaElement", {
                    id: recordElementId,
                    coa_id: property.account_id,
                }, headers).then(res => {
                    notifySuccess("Successfully deleted");
                    const lists = res.data;
                    setCoaElementlist(lists);
                }).catch((error) => {
                    notifyError(error.response.data.message);

                });
                setRecordElementId(0);
                setShowClassItmeDeleteModal(false);
            }

            return (
                <>
                    <Modal style={{paddingTop: "50px"}} show={showClassItmeDeleteModal} backdrop="static" size="md"
                           onHide={() => setShowClassItmeDeleteModal(false)}>
                        <form onSubmit={(e) => onDeleteRecord(e)}>
                            <Modal.Header>
                                <div className="modal-profile">
                                    <h2>Confirmation</h2>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="pr-3">
                                    <Col md="12">
                                        Are you sure you want to delete?
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div className="modal-footer">
                                <Button title="Close"
                                        className="btn-simple"
                                        type="button"
                                        variant="link"
                                        onClick={() => setShowClassItmeDeleteModal(false)}>
                                    Close
                                </Button>
                                <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                    Yes
                                </Button>
                            </div>
                        </form>
                    </Modal>
                </>
            );
        }

        function AddItems() {

            const addElementItems = async (e) => {
                e.preventDefault();
                await Axios.post(mainUrl + "addCoaElement", {
                    id: e.target.element_id.value,
                    coa_id: property.account_id,
                    code: e.target.element_code.value,
                    name: e.target.element_name.value,
                    description: e.target.element_description.value
                }, headers).then(res => {
                    if (e.target.id.value == 0) {
                        notifySuccess(' Added Successfully.');
                    } else {
                        notifySuccess(' Updated Successfully.');
                    }
                    setCoaElementlist(res.data);
                    setShowAddItems(false);
                }).catch(function (error) {
                    notifyError(error.response.data.message);
                    setShowAddItems(false);
                });
            };

            return (
                <>
                    <Modal style={{paddingTop: "50px"}} show={showAddItems} backdrop="static" size="md"
                           onHide={() => setShowAddItems(false)}>
                        <Form onSubmit={(e) => addElementItems(e)}>
                            <Modal.Header className="justify-content-center">
                                <div className="modal-profile">
                                    New Element
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="pr-3">
                                    <Input hidden={true} required name="element_id" id="element_id" value="0"
                                           type="hidden"/>
                                    <Col className="" md="12">
                                        <FormGroup>
                                            <label>Code <span className="text text-danger">*</span></label>
                                            <Input required name="element_code" id="element_code"
                                                   placeholder="Element Code" title="Element Code" type="text"/>
                                        </FormGroup>
                                    </Col>
                                    <Col className="" md="12">
                                        <FormGroup>
                                            <label>Name <span className="text text-danger">*</span></label>
                                            <Input required name="element_name" id="element_name"
                                                   placeholder="Element name" title="Element name" type="text"/>
                                        </FormGroup>
                                    </Col>

                                    <Col className="" md="12">
                                        <FormGroup>
                                            <label>Description <span className="text text-danger">*</span></label>
                                            <textarea className="form-control" required name="element_description"
                                                      id="element_description"
                                                      placeholder="Description . . ." title="Description "></textarea>
                                        </FormGroup>
                                    </Col>

                                </Row>

                            </Modal.Body>
                            <div className="modal-footer">
                                <Button title="Close"
                                        className="btn-simple"
                                        type="button"
                                        variant="link"
                                        onClick={() => setShowAddItems(false)}>
                                    Close
                                </Button>
                                <Button title="Insert & update  Record" className="btn-round" color="danger"
                                        type="submit">
                                    Add
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                </>
            );
        }

        let no = 1;
        return (
            <>
                <AddItems/>
                <DeleteItemClass/>
                <Modal show={showAddElementItem} backdrop="static" size="xl"
                       onHide={() => setShowAddElementItem(false)}>
                    <Modal.Header>
                        <div className="row" style={{width: "100vw"}}>
                            <div className="ml-right col-md-12">
                                <h4> Add Element </h4>
                            </div>

                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12">
                            <table className="table table-bordered">
                                <tbody>
                                <tr>
                                    <td style={{width: "50%"}}>Account Code</td>
                                    <td><span className="text-info">{property.account_code}</span></td>
                                </tr>
                                <tr>
                                    <td style={{width: "50%"}}>Account Name</td>
                                    <td><span className="text-info">{property.account_name}</span></td>
                                </tr>
                                <tr>
                                    <td style={{width: "50%"}}>Description</td>
                                    <td><span className="text-info">{property.account_description}</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <Row>
                            <Col md="12">
                                <div className="card-user">

                                    <CardBody>
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setShowAddItems(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                        <div style={{height: '350px', overflowY: 'scroll'}}>
                                            <Table>
                                                <thead className="text-primary">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Code</th>
                                                    <th>Name</th>
                                                    <th>Nature</th>
                                                    <th>Description</th>
                                                    <th className="text-right">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {coaElementlist.map((e) => (
                                                    <tr key={e.id}>
                                                        <td>{no++}</td>
                                                        <td style={{width: "20%"}}>{e.code}</td>
                                                        <td style={{width: "20%"}}>{e.name}</td>
                                                        <td style={{width: "20%"}}>
                                                            {(e.nature === 1) && <span className="badge badge-danger ">Debit</span>}
                                                            {(e.nature === 2) && <span className="badge badge-success ">Credit</span>}
                                                        </td>
                                                        <td style={{width: "30%"}}>{e.description}</td>

                                                        <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                            {e.deletable == 1 &&
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="outline-info"
                                                                                 id="dropdown-basic">
                                                                    <span className="nc-icon nc-bullet-list-67"/>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Button title="Update & Detail View Record"
                                                                            onClick={async () => {
                                                                                await setShowAddItems(true);
                                                                                document.getElementById('element_id').value = e.id;
                                                                                document.getElementById('element_code').value = e.code;
                                                                                document.getElementById('element_name').value = e.name;
                                                                                document.getElementById('element_description').value = e.description;
                                                                            }} className="btn-round dropdownlist"
                                                                            variant="light" type="Button">
                                                                        <span
                                                                            className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                                        Update & Detail View Record
                                                                    </Button>
                                                                    <br/>
                                                                    <Button title="Delete Record" onClick={() => {
                                                                        setRecordElementId(e.id);
                                                                        setShowClassItmeDeleteModal(true);
                                                                    }} className="btn-round dropdownlist"
                                                                            variant="light" type="Button"
                                                                    >
                                                                        <span
                                                                            className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                                        Delete Record
                                                                    </Button>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button title="Close"
                                className="btn-simple"
                                type="button"
                                variant="link"
                                onClick={() => setShowAddElementItem(false)}>
                            Close
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }

    function AddItem() {
        return (
            <>
                <Modal show={showModal} backdrop="static" size="xl" onHide={() => setShowModal(false)}>
                    <Form onSubmit={(e) => addCoa(e)}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                                New Account
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Input hidden={true} required name="account_id" id="account_id" value="0"
                                       type="hidden"/>

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Code <span className="text text-danger">*</span></label>
                                        <Input required name="account_code" id="account_code"
                                               placeholder="Account Code" title="Account Code" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Account Name <span className="text text-danger">*</span></label>
                                        <Input required name="account_name" id="account_name"
                                               placeholder="Account Name" title="Account Name" type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label>Description <span className="text text-danger">*</span></label>
                                        <textarea className="form-control" placeholder="description . . ."
                                                  name="account_description" id="account_description"></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <DeleteItem/>
                <AddElementItem/>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        {/*<div className="ml-right">*/}
                                        {/*    <h5> Coa List </h5>*/}
                                        {/*</div>*/}
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setShowModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Code</th>
                                        <th>Account Name</th>
                                        <th>Description</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "30%"}}>{e.code}</td>
                                            <td style={{width: "70%"}}>{e.name}</td>
                                            <td style={{width: "70%"}}>{e.description}</td>

                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {e.deletable == 1 &&
                                                        <>
                                                            <Button title="Update & Detail View Record"
                                                                    onClick={async () => {
                                                                        await setShowModal(true);
                                                                        document.getElementById('account_name').value = e.name;
                                                                        document.getElementById('account_code').value = e.code;
                                                                        document.getElementById('account_description').value = e.description;
                                                                        document.getElementById('account_id').value = e.id;
                                                                    }} className="btn-round dropdownlist"
                                                                    variant="light"
                                                                    type="Button">
                                                                <span
                                                                    className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                                Update & Detail View Record
                                                            </Button>
                                                            <br/>
                                                            <Button title="Delete Record" onClick={() => {
                                                                setRecordId(e.id);
                                                                setShowDeleteModal(true);
                                                            }} className="btn-round dropdownlist" variant="light"
                                                                    type="Button">
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                                Delete Record
                                                            </Button>
                                                            <br/>
                                                        </>
                                                        }
                                                        <Button title="Delete Record"
                                                                onClick={() => {
                                                                    setProperty({
                                                                        "account_id": e.id,
                                                                        "account_code": e.code,
                                                                        "account_name": e.name,
                                                                        "account_description": e.description,
                                                                    });
                                                                    setRecordId(e.id);
                                                                    setShowAddElementItem(true);
                                                                }}
                                                                className="btn-round dropdownlist" variant="light"
                                                                type="Button"
                                                        >
                                                            <span
                                                                className="nc-icon nc-chart-bar-32 text-info"/>&nbsp;&nbsp;
                                                            Add Element
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
