import Axios from "axios";
import {mainUrl} from "../../common/common";
import {CardBody, Input} from "reactstrap";
import {Button, Col, FormGroup, Row} from "react-bootstrap";
import React from "react";
import axios from "axios";


function SearchPurchase(props) {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const searchClass = async (e) => {
        e.preventDefault();
        const f = e.target;
        props.setSearchParameter({
            'province_id': f.sh_province_id.value,
            'email': f.sh_email.value,
            'branch_id': f.sh_branch_id.value,
            'project_id': f.sh_project_id.value,
            'position_id': f.sh_position_id.value,
            'employee_id': f.sh_employee_id.value
        });
        props.setSearchStatus(true);
        const {data} = await Axios.post(mainUrl + "searchClass?page=1",
            {
                'province_id': f.sh_province_id.value,
                'email': f.sh_email.value,
                'branch_id': f.sh_branch_id.value,
                'project_id': f.sh_project_id.value,
                'position_id': f.sh_position_id.value,
                'employee_id': f.sh_employee_id.value
            }, {headers});
        const lists = data;
        props.setList(lists);
    };

    return (
        <>
            <CardBody className="mr-4 ml-4" style={{minHeight:"100%"}}>
                <form onSubmit={(e) => searchClass(e)}>
                    <Row>

                        <Col className="px-1" md="3">
                            <FormGroup>
                                <label>Year <span className="text text-danger">*</span></label>
                                <select title="Select Project" name="sh_project_id" id='sh_project_id'
                                        className="form-control">
                                    <option value=""></option>
                                    {
                                        props.yearList.map((e, index) => (
                                            <option key={index}
                                                    value={e.id}>{e.name}</option>))
                                    }
                                </select>
                            </FormGroup>
                        </Col>
                        <Col className="px-1" md="3">
                            <FormGroup>
                                <label>Purchase Code <span className="text text-danger">*</span></label>
                                <select title="Select Project" name="sh_project_id" id='sh_project_id'
                                        className="form-control">
                                    <option value=""></option>
                                    {
                                        props.branchList.map((e, index) => (
                                            <option key={index}
                                                    value={e.id}>{e.name}</option>))
                                    }
                                </select>
                            </FormGroup>
                        </Col>
                        <Col className="px-1" md="3">
                            <FormGroup>
                                <label>Chart of Account <span className="text text-danger">*</span></label>
                                <select title="Select Project" name="sh_project_id" id='sh_project_id'
                                        className="form-control">
                                    <option value=""></option>
                                    {
                                        props.branchList.map((e, index) => (
                                            <option key={index}
                                                    value={e.id}>{e.name}</option>))
                                    }
                                </select>
                            </FormGroup>
                        </Col> <Col className="px-1" md="3">
                        <FormGroup>
                            <label>Element<span className="text text-danger">*</span></label>
                            <select title="Select Project" name="sh_project_id" id='sh_project_id'
                                    className="form-control">
                                <option value=""></option>
                                {
                                    props.branchList.map((e, index) => (
                                        <option key={index}
                                                value={e.id}>{e.name}</option>))
                                }
                            </select>
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                        <div className="ml-end">
                            <Button title="Search" className="btn btn-danger btn-round" type="submit">
                                <span className="nc-icon nc-zoom-split"/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Search
                                &nbsp;
                            </Button>
                        </div>
                    </Row>
                </form>
            </CardBody>
        </>
    );
}


export default SearchPurchase;
