import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../../common/common";
import {Button, Col, Form, FormGroup, Modal, Row, Table, Spinner, Dropdown} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";
import {maxWidth} from "@material-ui/system";

export default function Teacher(props) {
    const [yearModal, setTeacherModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [list, setList] = React.useState([]);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetchList();
    }, []);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const fetchList = async () => {
        const {data} = await Axios.post(mainUrl + "getClassTeacher",{"class_id":props.classId} ,{headers});
        const lists = data;
        setList(lists);;
        setIsLoading(true);
    };

    const addTeacher = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addClassTeacher", {
            id: e.target.rec_id.value,
            teacher_id: e.target.teacher_id.value,
            subject_id: e.target.subject_id.value,
            percentage: e.target.percentage.value,
            from: e.target.from.value,
            to: e.target.to.value,
            class_id: props.classId,
            branch_id: props.branchId,
        }, {headers}).then(res => {
            if (e.target.rec_id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setTeacherModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setTeacherModal(false);
        });
    };

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    return (
        <>
            <link crossOrigin="use-credentials"/>
            <Row>
                {/*<Col md="3"></Col>*/}
                <Col className="pr-3" md="12">
                    {isLoading === false ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="info"/>
                        </div>
                    ) : (
                        <div>
                            <AddItem teacherList={props.teacherList}/>
                            <TableData/>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );

    function DeleteItem() {

        const onDeleteRecord = async (e) => {
            e.preventDefault();
            await Axios.post(mainUrl + "deleteStaff", {
                id: recordId
            }, props.headers).then(res => {
                notifySuccess("Successfully deleted");
                const lists = res.data;
                setList(lists);
            }).catch((error) => {
                notifyError(error.response.data.message);

            });
            setRecordId(0);
            setShowDeleteModal(false);
        }

        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddItem(prop) {

        return (
            <>
                <Modal show={yearModal} backdrop="static" size="lg" style={{marginTop:"100px"}} onHide={() => setTeacherModal(false)}>
                    <Form onSubmit={(e) => addTeacher(e)}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                                New Teacher
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Input type="hidden" name="rec_id" id="rec_id" value="0"/>
                                        <label>Teacher<span className="text text-danger">*</span></label>
                                        <select className="form-control" required name="teacher_id" id="teacher_id">
                                            <option value=""></option>
                                            {prop.teacherList.map((e) => (
                                                <option key={e.id}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Subject<span className="text text-danger">*</span></label>
                                        <select className="form-control" name="subject_id" id="subject_id">
                                            <option value="1">Grammar</option>
                                            <option value="2">Conversation</option>
                                            <option value="3">Other</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>Percentage<span className="text text-danger">*</span></label>
                                        <div className="input-group">
                                            <input type="number" className=" " style={{maxWidth:"80%"}}  name="percentage" min="0" max="100" id="percentage"/>
                                            <span style={{backgroundColor: "gray", minWidth: "30px", textAlign: "center", fontSize: "15pt"}}> % </span>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>From<span className="text text-danger">*</span></label>
                                        <Input className="form-control" name="from" id="from" type="date"/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>To<span className="text text-danger">*</span></label>

                                        <Input className="form-control" name="to" id="to" type="date"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setTeacherModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <DeleteItem/>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="">
                                            <span className="h4 ">Teacher List</span>
                                            <Button disabled={props.statusRecord} title="Add New Record"  style={{float: 'inline-end'}} className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setTeacherModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Teacher Name</th>
                                        <th>Subject</th>
                                        <th>Percentage</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "20%"}}>{e.teacher_name}</td>
                                            <td style={{width: "20%"}}>
                                                {(e.subject_id === 3) && <span className="badge badge-success ">Other</span>}
                                                {(e.subject_id === 2) && <span className="badge badge-success ">Conversation</span>}
                                                {(e.subject_id === 1) && <span className="badge badge-success ">Grammar</span>}
                                            </td>
                                            <td style={{width: "20%"}}>{e.percentage}</td>
                                            <td style={{width: "20%"}}>{e.from}</td>
                                            <td style={{width: "20%"}}>{e.to}</td>
                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Update & Detail View Record"
                                                                onClick={async () => {
                                                                    await setTeacherModal(true);
                                                                    document.getElementById('year_name').value = e.name;
                                                                    document.getElementById('year_id').value = e.id;
                                                                }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
