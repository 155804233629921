import Axios from "axios";
import {mainUrl} from "../../common/common";
import {CardBody, Input} from "reactstrap";
import {Button, Col, FormGroup, Row} from "react-bootstrap";
import React from "react";
import axios from "axios";


function SearchProfile(props) {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const searchProfile = async (e) => {
        e.preventDefault();
        const f = e.target;
        props.setSearchParameter({
            'province_id': f.sh_province_id.value,
            'email': f.sh_email.value,
            'branch_id': f.sh_branch_id.value,
            'project_id': f.sh_project_id.value,
            'position_id': f.sh_position_id.value,
            'employee_id': f.sh_employee_id.value
        });
        props.setSearchStatus(true);
        const {data} = await Axios.post(mainUrl + "searchProfile?page=1",
            {
                'province_id': f.sh_province_id.value,
                'email': f.sh_email.value,
                'branch_id': f.sh_branch_id.value,
                'project_id': f.sh_project_id.value,
                'position_id': f.sh_position_id.value,
                'employee_id': f.sh_employee_id.value
            }, {headers});
        const lists = data;
        props.setList(lists);
    };

    return (
        <>
            <CardBody className="mr-4 ml-4">
                <form onSubmit={(e) => searchProfile(e)}>
                    <Row>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label> Student Id <span
                                    className="text text-danger">*</span></label>
                                <input className="form-control" title="Student Id" name="sh_employee_id" id="sh_employee_id"
                                       placeholder="Student Id" type="text"/>
                            </FormGroup>
                        </Col>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label> Phone Number <span
                                    className="text text-danger">*</span></label>
                                <input className="form-control" title="Phone Number" name="phone_number" id="phone_number"
                                       placeholder="Phone Number" type="email"/>
                            </FormGroup>
                        </Col>
                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>Class Code <span className="text text-danger">*</span></label>
                                <input type="text" className="form-control" name="class_code" id="class_code" placeholder="Class Code" title="Class Code"/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <div className="ml-end">
                            <Button title="Search" className="btn btn-danger btn-round" type="submit">
                                <span className="nc-icon nc-zoom-split"/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Search
                                &nbsp;
                            </Button>
                        </div>
                    </Row>
                </form>
            </CardBody>
        </>
    );
}


export default SearchProfile;
