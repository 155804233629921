import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../../common/common";
import {Button, Col, Form, FormGroup, Modal, Row, Table, Spinner, Dropdown} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";

export default function Vendor(headers) {
    const [dataForm, setDataForm] = React.useState({});
    const [vendorModal, setVendorModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [list, setList] = React.useState([]);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetchList();
    }, []);
    const fetchList = async () => {
        const {data} = await Axios.get(mainUrl + "getVendor", headers);
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };
    const addVendor = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addVendor", {
            id: e.target.ven_id.value,
            description: e.target.ven_description.value,
            fax: e.target.ven_fax.value,
            phone: e.target.ven_phone.value,
            address: e.target.ven_address.value,
            cont_dep_name: e.target.cont_dep_name.value,
            company_name: e.target.company_name.value

        }, headers).then(res => {
            if (e.target.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setVendorModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setVendorModal(false);
        });
    };


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    return (
        <>

            <Row>
                <Col md="3"></Col>
                <Col className="pr-3" md="6">
                    {isLoading === false ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="info"/>
                        </div>
                    ) : (
                        <div>
                            <AddItem dataForm={dataForm}/>
                            <TableData/>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );

    function DeleteItem() {

        const onDeleteRecord = async (e) => {
            e.preventDefault();
            await Axios.post(mainUrl + "deleteVendor", {
                id: recordId
            }, headers).then(res => {
                notifySuccess("Successfully deleted");
                const lists = res.data;
                setList(lists);
            }).catch((error) => {
                notifyError(error.response.data.message);

            });
            setRecordId(0);
            setShowDeleteModal(false);
        }

        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddItem(prop) {


        const [departmentList, setDepartmentList] = React.useState([]);

        useEffect(() => {
            departList();
            setTimeout(async () => {
                if (prop.dataForm.department_id) {
                    if (document.getElementById('department_id')) {
                        document.getElementById('department_id').value = prop.dataForm.department_id;
                    }
                }

            }, 1000);

        }, []);
        const departList = async () => {
            const {data} = await Axios.get(mainUrl + "getDepartment", headers);
            const lists = data;
            setDepartmentList(lists);
            setIsLoading(true);
        };

        return (
            <>
                <Modal show={vendorModal} backdrop="static" size="lg" onHide={() => setVendorModal(false)}>
                    <Form onSubmit={(e) => addVendor(e)}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                                New Vendor
                            </div>
                        </Modal.Header>
                        <Modal.Body>

                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>

                                        <input  name="ven_id" id="ven_id" type="hidden" defaultValue={prop.dataForm.id}/>

                                        <label>Company Name <span className="text text-danger">*</span></label>
                                        <Input required name="company_name" id="company_name"
                                               placeholder="Company Name" title="Company Name" type="text" defaultValue={prop.dataForm.company_name}/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Contact or Department <span className="text text-danger">*</span></label>
                                        <Input required name="cont_dep_name" id="cont_dep_name"
                                               placeholder="Contact or Department" title="Contact or Department" type="text" defaultValue={prop.dataForm.cont_dep_name}/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Address <span className="text text-danger">*</span></label>
                                        <Input required name="ven_address" id="ven_address"
                                               placeholder="Address" title="Address"
                                               defaultValue={prop.dataForm.address}
                                               type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>

                                        <label>Phone<span className="text text-danger">*</span></label>

                                        <Input type="tel"
                                               required
                                               name="ven_phone" id="ven_phone"
                                               placeholder="Phone Number"
                                               defaultValue={prop.dataForm.phone}
                                               pattern="[1-9]{1}[0-9]{8}"/>
                                        <small className="badge badge-default"> Format: 700123456 </small>
                                        <br/>

                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Fax</label>
                                        <Input required name="ven_fax" id="ven_fax"
                                               placeholder="Vendor Fax" title="Vendor Fax "
                                               defaultValue={prop.dataForm.fax}
                                               type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label>Description </label>
                                        <textarea className="form-control" placeholder="Description . . ."
                                                  defaultValue={prop.dataForm.description}
                                                  name="ven_description" id="ven_description"></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setVendorModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <DeleteItem/>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">

                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setDataForm({
                                                            "id":"0",
                                                            "department_id":"0",
                                                            "vendor_name":"",
                                                            "duration":"12",
                                                            "monthly_fee":"0",
                                                            "admission_fee":"0",
                                                            "examination_fee":"0",
                                                            "material_fee":"0",
                                                            "description":"",
                                                        });
                                                        setVendorModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Company Name</th>
                                        <th>Contract Department Name</th>
                                        <th>Address</th>
                                        <th>Phone</th>
                                        <th>Fax</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "20%"}}>{e.company_name}</td>
                                            <td style={{width: "20%"}}>{e.cont_dep_name}</td>
                                            <td style={{width: "20%"}}>{e.address}</td>
                                            <td style={{width: "20%"}}>{e.phone}</td>
                                            <td style={{width: "20%"}}>{e.fax}</td>
                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Update & Detail View Record"
                                                                onClick={async () => {
                                                                    setDataForm(e);
                                                                    await setVendorModal(true);
                                                                }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
