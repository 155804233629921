import React, {useEffect} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, CardBody, Col, Input, Row} from "reactstrap";
import {Form, FormGroup} from "react-bootstrap";

function SearchItem(props) {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    useEffect(() => {
    }, []);

    var startDate = new Date();
    startDate.setDate(startDate.getDate()-30);
    var startDates= startDate.toISOString().substr(0, 10);

    var endDate = new Date();
    endDate.setDate(endDate.getDate());
    var endDates = endDate.toISOString().substr(0, 10);


    const setReport = async (e) => {
        e.preventDefault();
        props.setList([]);
        props.setReportType(e.target.value);
    }
    const generateReport = async (e) => {
        e.preventDefault();
        const f = e.target;
            const {data} = await Axios.post(mainUrl + "generateReport", {
                start_date: f.start_date.value,
                end_date: f.end_date.value,
                type: f.report_type_id.value
            }, {headers});
            const lists = data;
            props.setList(lists);

    }
    return (
        <>
            <CardBody className="mr-4 ml-4">
                <Form onSubmit={(e) => generateReport(e)}>
                    <Row>
                        <Col className="px-1" md="6">
                            <FormGroup>
                                <label>
                                    Report Type <span className="text text-danger">*</span></label>
                                <select name="report_type_id" id="report_type_id" title="Report type" defaultValue={props.reportType}
                                        className="form-control" onChange={(e) => setReport(e)}>
                                    <option value="0"></option>
                                    <option value="1">Chart of Account Report</option>
                                    <option value="2">Chart of Account Detail Report</option>

                                </select>
                            </FormGroup>
                        </Col>

                        <Col className="px-1" md="3">
                            <FormGroup>
                                <label>Start Date <span className="text text-danger">*</span></label>
                                <Input type="date" title="Start Date" placeholder="Start Date" defaultValue={startDates}
                                       id="start_date"
                                       name="start_date"/>
                            </FormGroup>
                        </Col>
                        <Col className="px-1" md="3">
                            <FormGroup>
                                <label>End Date <span className="text text-danger">*</span></label>
                                <Input type="date" title="End Date" placeholder="End Date" id="end_date"
                                       defaultValue={endDates}
                                       name="end_date"/>
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>
                        <div className="ml-end">

                            <Button title="Generate Report" className="btn btn-danger btn-round" type="submit">
                                <span className="nc-icon nc-zoom-split"/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Generate
                                &nbsp;
                            </Button>
                        </div>
                    </Row>
                </Form>
            </CardBody>
        </>
    );
}



export default SearchItem;
