import React from "react";
import Position from "./Setup/Position";
import Branch from "./Branch";
import {CardHeader, CardBody, CardTitle} from "reactstrap";
import {Tabs, Card, Row, Col, Tab} from "react-bootstrap";
import {Toaster} from "react-hot-toast";
import Project from "./Project";
import Setup from "./Setup";
import Year from "./Setup/Year";
import Department from "./Setup/Department";
import Program from "./Setup/Program";
import Section from "./Setup/Section";
import Coa from "./Coa";
import Vendor from "./Setup/Vendor";
import UnitType from "./Setup/UnitType";

function Settings() {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    return (
        <>
            <div className="content">

                <Row>
                    <Col md="12">
                        <Card className=" ">
                            <CardHeader style={{backgroundColor: "#80808029"}}>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h3> Setting </h3>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>

                            <CardBody className="all-icons mr-4 ml-4">
                                <div id="icons-wrapper">
                                    <Tabs defaultActiveKey="Setup" id=" " className="">
                                        <Tab eventKey="Setup" title="Setup" >
                                            <Setup headers={headers}/>
                                        </Tab>
                                        <Tab eventKey="Branch" title="Branch">
                                            <Branch headers={headers}/>
                                        </Tab>
                                        <Tab eventKey="Year" title="Year">
                                            <Year headers={headers}/>
                                        </Tab>
                                        <Tab eventKey="Department" title="Department">
                                            <Department headers={headers}/>
                                        </Tab>
                                        <Tab eventKey="Program" title="Program">
                                            <Program headers={headers}/>
                                        </Tab>
                                        <Tab eventKey="Position" title="Position">
                                            <Position headers={headers}/>
                                        </Tab>

                                        <Tab eventKey="Vendor" title="Vendor">
                                            <Vendor headers={headers}/>
                                        </Tab>
                                        <Tab eventKey="UnitType" title="Unit Type">
                                            <UnitType headers={headers}/>
                                        </Tab>

                                        <Tab eventKey="Coa" title="Chart of Account">
                                            <Coa headers={headers}/>
                                        </Tab>

                                        {/*<Tab eventKey="Section" title="Section">*/}
                                        {/*    <Section headers={headers}/>*/}
                                        {/*</Tab>*/}


                                    </Tabs>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default Settings;
