import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Modal, Row, Spinner, Table, Dropdown, FormGroup} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";
import image from "../../assets/img/image.png";
import {border, height, width} from "@material-ui/system";

// import Pagination from "@material-ui/lab/Pagination";
// import Dropdown from "bootstrap/js/src/dropdown";

import Atarud from "./../../assets/img/atarud.jpg";
import Atarud1 from "./../../assets/img/atarud.png";
import AtarudBackground from "./../../assets/img/atarudpng.jpg";
import Bag from "./../../assets/img/std_card2.jpg";
function ListStudent(props) {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showAddToClassModal, setShowAddToClassModal] = React.useState(false);
    const [showFeePaymentModal, setShowFeePaymentModal] = React.useState(false);
    const [studentCardModal, setStudentCardModal] = React.useState(false);
    const [accountStatus, setAccountStatus] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [recordInfo, setRecordInfo] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        if (props.list.data) {
            setRecordList(props.list.data);
        }
    })

    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteStudent", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Successfully deleted");
            const lists = res.data;
            props.setList(lists);
        }).catch((error) => {
            props.notifyError(error.response.data.message);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }
    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchStudent?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getStudent?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }

        setRecordId(0);
        setShowDeleteModal(false);
    }

    const addToClass = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "blockAccount", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Account Status has been changed!");
            for (const i in props.list.data) {
                if (props.list.data[i].id == recordId) {
                    if (props.list.data[i].is_active == 1) {
                        props.list.data[i].is_active = 0;
                    } else {
                        props.list.data[i].is_active = 1;
                    }
                    break;
                }
            }
            setShowAddToClassModal(false);
        }).catch((error) => {
            props.notifyError(error.response.data.message);

            setShowAddToClassModal(false);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }


    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }
 
    function StudentCard() {
        return (
            <>
                <Modal show={studentCardModal} backdrop="static" size="lg"
                       onHide={() => setStudentCardModal(false)}>
                    <form onSubmit={(e) => addToClass(e)}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                                Student Card
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{marginTop: '20px', paddingBottom: '50px', backgroundColor: '#fdd'}}>
                                <Row className="pr-3" style={{padding: '20px'}}>
                                    <Col md="2"></Col>
                                    <Col>
                                        <Row style={{
                                            border: '1px solid black',
                                            padding: '10px',
                                            boxShadow: '0px 0px 10px #000',  backgroundImage: `url(${Bag})` ,backgroundSize: '500px auto',backgroundRepeat: 'no-repeat',
                                        }}>
                                            <Row>
                                                <Col style={{margin:'0 auto',paddingTop:'100px'}}>
                                                 </Col>
                                            </Row>
                                            <Row style={{
                                             }}>
                                                <Col md="4">
                                                    <div style={{
                                                        border: '1px solid black',
                                                        height: '100px',
                                                        width: '100px'
                                                    }}>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <table className="">

                                                        <tr><td>
                                                            <div style={{height:'25px'}}></div>
                                                        </td></tr>
                                                        <tr>
                                                            <th>ID</th>
                                                            <td style={{whiteSpace:'nowrap'}}>(STU-{("0000" + recordInfo.id).slice(-5)})</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <td>{recordInfo.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>FName</th>
                                                            <td>{recordInfo.fname}</td>
                                                        </tr>

                                                        <tr>
                                                            <th style={{whiteSpace:'nowrap'}}>Registration Date</th>
                                                            <td style={{whiteSpace:'nowrap'}}>{recordInfo.created_at}</td>
                                                        </tr>
                                                        <tr><td>
                                                            <div style={{height:'50px'}}></div>
                                                        </td></tr>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col md="2"></Col>
                                </Row>

                            </div>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button
                                title="Close"
                                className="btn-simple"
                                type="button"
                                variant="link"
                                onClick={() => {
                                    setStudentCardModal(false);
                                }}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Print
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    let rowNo = 1;
    return (
        <>
            <DeleteItem/> 
            <StudentCard/>
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> User Student </h2>
                                    </div>
                                    <div className="ml-end">
                                        <Button title="Add New Record" className="btn-success" variant="primary"
                                                onClick={() => {
                                                    props.setAttachFiles(false);
                                                    props.setShowModal(true);
                                                    props.setSubAdminStatus(1);
                                                    props.setLocalImageStudent("");
                                                    sessionStorage.setItem("image", "");
                                                }}>
                                            <span className="nc-icon nc-simple-add"/>
                                        </Button>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>
                        {props.isLoading === false ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : (
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Student Id</th>
                                        <th>Student Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th className="text-right ">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {recordList.map((e, index) => (
                                        <tr key={index}>
                                            {/*className={rowNo % 2 == 0 ? "bg-dark bg-opacity-10" : ""}*/}
                                            <td>{rowNo++}</td>
                                            <td style={{width: "25%"}}> (STU-{("0000" + e.id).slice(-5)})</td>
                                            <td style={{width: "25%"}}> {e.name}</td>
                                            <td style={{width: "25%"}}>{e.email}</td>
                                            <td style={{width: "25%"}}>{e.phone}</td>

                                            <td>

                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>


                                                        <Button title="Update & Detail View Record"
                                                                style={{whiteSpace: "nowrap"}}
                                                                className="btn-round" variant="light" type="Button"
                                                                onClick={async () => {

                                                                    await props.setShowModal(true);
                                                                    setRecordId(e.id);
                                                                    document.getElementById('ids').value = e.id;
                                                                    document.getElementById('employee_ids').value = e.employee_id;
                                                                    document.getElementById('name').value = e.name;
                                                                    document.getElementById('phone').value = e.phone;
                                                                    document.getElementById('father_name').value = e.father_name;
                                                                    document.getElementById('email').value = e.email;
                                                                    document.getElementById('phone').value = e.phone;
                                                                    document.getElementById('gender_id').value = e.gender_id;
                                                                    document.getElementById('user_type_id').value = e.user_type_id;
                                                                    document.getElementById('salary').value = e.salary;
                                                                    // document.getElementById('attachment').value = e.attachment;
                                                                    props.setAttachFiles(false);
                                                                    props.setSubAdminStatus(e.user_type_id);
                                                                    if (e.attachment != null && e.attachment != "") {
                                                                        props.setAttachFiles(true);
                                                                    }
                                                                    document.getElementById('status_id').value = e.status_id;
                                                                    document.getElementById('image').value = e.image;
                                                                    sessionStorage.setItem("image", e.image);
                                                                    props.setLocalImageStudent(e.image);
                                                                    document.getElementById('project_id').value = e.project_id;
                                                                    document.getElementById('province_id').value = e.province_id;
                                                                    document.getElementById('position_id').value = e.position_id;
                                                                    document.getElementById('branch_id').value = e.branch_id;

                                                                    props.setNeedObject({
                                                                        'project_id': e.project_id,
                                                                        'province_id': e.province_id,
                                                                        'branch_id': e.branch_id,
                                                                        'class_id': e.class_id
                                                                    });
                                                                    props.setUpdateRecordStatus(true);


                                                                }}>

                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <Button title="Update & Detail View Record"
                                                                className="btn-round dropdownlist " variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setRecordId(e.id);
                                                                    setShowDeleteModal(true);
                                                                }}>
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button> 
                                                        <Button title="Forgot Password" disabled={false}
                                                                className="btn-round dropdownlist" variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setRecordId(e.id);
                                                                    setRecordInfo(e);

                                                                    setStudentCardModal(true);
                                                                }}
                                                        >
                                                        <span
                                                            className="nc-icon nc-single-copy-04 text-info"/> &nbsp;&nbsp;
                                                            Student Card
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </Table>
                                <div className="page-content-center">
                                    {loadMore === true ? (
                                            <div className="text-center">
                                                <Spinner animation="border" variant="info"/>
                                            </div>
                                        ) :
                                        (props.list.last_page >= pageNo ?
                                            <button className="btn  bg-light text-info" title="Load More"
                                                    onClick={(pageNumber => {
                                                        nextPageLoadList(pageNumber);
                                                    })}>
                                                <span className="nc-icon nc-simple-add text-info"/>
                                            </button>

                                            : "")}
                                </div>
                            </CardBody>
                        )
                        }
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListStudent;
