import Axios from "axios";
import {mainUrl} from "../../common/common";
import React, {forwardRef, useRef} from "react";
import {Button, Card, CardHeader, CardTitle, Col, Row} from "reactstrap";
import ReactToPrint from "react-to-print";
import {Spinner} from "react-bootstrap";

function TableData(props) {

    const update = async (e) => {
        e.preventDefault();
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Header': '*',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }

        var status = document.getElementsByName('status[]');
        var emp = document.getElementsByName('employee_id[]');
        var date = document.getElementsByName('date[]');
        var remark = document.getElementsByName('remark[]');
        var k = "[";
        for (var i = 0; i < emp.length; i++) {

            var s = status[i];
            var e = emp[i];
            var d = date[i];
            var r = remark[i];
            k = k + '{' + '"status":' + s.value + ', "date": "' + d.value+ '", "remark": "' + r.value + '", "employee_id":' + e.value + "}";
            if (i < emp.length - 1) {
                k = k + ',';
            }
        }
        k = k + "]";
        console.log(k);
        var obj = JSON.parse(k);
        const {data} = await Axios.post(mainUrl + "resultAnalysisReport", {
            value: obj,
        }, {headers});
        const lists = data;
    }
    let re=0;
    const ComponentToPrint = forwardRef((prop, reference) => (
        <div className="card table table-reference" ref={reference}>
            <table className="table ">
                <thead className="text-primary">
                <tr>
                    <td colSpan="10" className="text-primary content-center h3 p-5"
                        style={{textAlign: 'center'}}>
                        Chart of Account Detail Report
                    </td>
                </tr>
                <tr>
                    {/*<th>No</th>*/}
                    <th>COA</th>
                    <th>Element </th>
                    <th>Credit</th>
                    <th>Debit</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.list.map((e, index) => (
                        <>
                            {e.coa_id!=re && <tr><td colSpan={4}><span>{e.coa_code} {e.coa_name} {(re = e.coa_id)}</span></td></tr>}
                        <tr key={index}>
                            {/*<td>{index + 1}</td>*/}
                            {/*<td>{e.coa_code} {e.coa_name}</td>*/}
                            <td></td>
                            <td>{e.element_code} {e.element_name}</td>
                            <td>{e.total_credit}</td>
                            <td>{e.total_debit}</td>
                        </tr>
                        </>
                ))}
                </tbody>
            </table>
        </div>
    ));
    const ComponentToPrint1 = forwardRef((prop, reference) => (
        <div className="card table table-reference p-4" ref={reference}>

            <form onSubmit={(e) => update(e)}>
                <table className="table">
                    <thead className="text-primary">
                    <tr>
                        <td colSpan="10" className="text-primary content-center h3 p-5"
                            style={{textAlign: 'center'}}>
                            Chart of Account Simple Report
                        </td>
                    </tr>
                    <tr>
                        <th>No</th>
                        <th>Chart of Account Type</th>
                        <th>Credit</th>
                        <th>Debit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.list.map((e, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{e.coa_code} {e.coa_name}</td>
                            <td>{e.total_credit}</td>
                            <td>{e.total_debit}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </form>
        </div>
    ));


    let componentRef = useRef();

    // let rowNumber = 1;
    return (
        <>

            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> Result Report </h2>
                                    </div>
                                    <div className="ml-end">
                                        <ReactToPrint
                                            trigger={() => <button title="Print Report"
                                                                   className=" btn btn-dark">Print</button>}
                                            content={() => componentRef.current}
                                        />
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>

                        {props.isLoading==0 ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : ""}

                        {( props.reportType == 1) ?
                            <ComponentToPrint1 ref={componentRef}/> : ""
                        }


                        {(  props.reportType == 2) ?
                            <ComponentToPrint ref={componentRef}/>  : ""
                        }

                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default TableData;
