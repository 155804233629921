
const pashtoWords= {
    "totalAdminUser": "تعداد کاربران ادمین",
    "mis_name": "سیستم مدیریت عطارد"
    ,
    "dashboard": "داشبورد",
    "userProfile": "مشخصات کاربر",
    "setting": "تنظیمات",
    "report": "راپور"
}


export default pashtoWords;
