import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Form, FormGroup, Modal, Row, Table, Spinner, Dropdown} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";

export default function Branch(headers) {
    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showAddClassItem, setShowAddClassItem] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [list, setList] = React.useState([]);
    const [property, setProperty] = React.useState({});

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetchList();
    }, []);
    const fetchList = async () => {
        const {data} = await Axios.get(mainUrl + "getBranch", headers);
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };
    const addBranch = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addBranch", {
            id: e.target.branch_id.value,
            name: e.target.branch_name.value,
            address: e.target.branch_address.value,
            phone: e.target.branch_phone.value,
            email: e.target.branch_email.value,
            description: e.target.branch_description.value
        }, headers).then(res => {
            if (e.target.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setShowModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setShowModal(false);
        });
    };

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    return (
        <>

            <Row>
                <Col md="3"></Col>
                <Col className="pr-3" md="6">
                    <div className="">
                        {isLoading === false ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : (
                            <div>
                                <AddItem/>
                                <TableData/>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );

    function DeleteItem() {

        const onDeleteRecord = async (e) => {
            e.preventDefault();
            await Axios.post(mainUrl + "deleteBranch", {
                id: recordId
            }, headers).then(res => {
                notifySuccess("Successfully deleted");
                const lists = res.data;
                setList(lists);
            }).catch((error) => {
                notifyError(error.response.data.message);

            });
            setRecordId(0);
            setShowDeleteModal(false);
        }

        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddClassItem() {

        const [classroomlist, setClassroomlist] = React.useState([]);
        const [showAddItems, setShowAddItems] = React.useState(false);
        const [showClassItmeDeleteModal, setShowClassItmeDeleteModal] = React.useState(false);
        const [recordClassId, setRecordClassId] = React.useState(0);
        useEffect(() => {
            fetchClassRoomList();
        }, []);
        const fetchClassRoomList = async () => {
            const {data} = await Axios.post(mainUrl + "getClassRoom", {
                branch_id: property.branch_id,
            }, headers);
            const lists = data;
            setClassroomlist(lists);
        };

        function DeleteItemClass() {
            const onDeleteRecord = async (e) => {
                e.preventDefault();
                await Axios.post(mainUrl + "deleteClassRoom", {
                    id: recordClassId,
                    branch_id: property.branch_id,
                }, headers).then(res => {
                    notifySuccess("Successfully deleted");
                    const lists = res.data;
                    setClassroomlist(lists);
                }).catch((error) => {
                    notifyError(error.response.data.message);

                });
                setRecordClassId(0);
                setShowClassItmeDeleteModal(false);
            }

            return (
                <>
                    <Modal style={{paddingTop: "50px"}} show={showClassItmeDeleteModal} backdrop="static" size="md"
                           onHide={() => setShowClassItmeDeleteModal(false)}>
                        <form onSubmit={(e) => onDeleteRecord(e)}>
                            <Modal.Header>
                                <div className="modal-profile">
                                    <h2>Confirmation</h2>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="pr-3">
                                    <Col md="12">
                                        Are you sure you want to delete?
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div className="modal-footer">
                                <Button title="Close"
                                        className="btn-simple"
                                        type="button"
                                        variant="link"
                                        onClick={() => setShowClassItmeDeleteModal(false)}>
                                    Close
                                </Button>
                                <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                    Yes
                                </Button>
                            </div>
                        </form>
                    </Modal>
                </>
            );
        }

        function AddItems() {

            const addClassItems = async (e) => {
                e.preventDefault();
                await Axios.post(mainUrl + "addClassRoom", {
                    id: e.target.class_id.value,
                    branch_id: property.branch_id,
                    name: e.target.classroom_name.value
                }, headers).then(res => {
                    if (e.target.id.value == 0) {
                        notifySuccess(' Added Successfully.');
                    } else {
                        notifySuccess(' Updated Successfully.');
                    }
                    setClassroomlist(res.data);
                    setShowAddItems(false);
                }).catch(function (error) {
                    notifyError(error.response.data.message);
                    setShowAddItems(false);
                });
            };

            return (
                <>
                    <Modal style={{paddingTop: "50px"}} show={showAddItems} backdrop="static" size="md"
                           onHide={() => setShowAddItems(false)}>
                        <Form onSubmit={(e) => addClassItems(e)}>
                            <Modal.Header className="justify-content-center">
                                <div className="modal-profile">
                                    New Class
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="pr-3">
                                    <Input hidden={true} required name="class_id" id="class_id" value="0"
                                           type="hidden"/>
                                    <Col className="" md="12">
                                        <FormGroup>
                                            <label>Class name <span className="text text-danger">*</span></label>
                                            <Input required name="classroom_name" id="classroom_name"
                                                   placeholder="Classroom name" title="Classroom name" type="text"/>
                                        </FormGroup>

                                    </Col>

                                </Row>

                            </Modal.Body>
                            <div className="modal-footer">
                                <Button title="Close"
                                        className="btn-simple"
                                        type="button"
                                        variant="link"
                                        onClick={() => setShowAddItems(false)}>
                                    Close
                                </Button>
                                <Button title="Insert & update  Record" className="btn-round" color="danger"
                                        type="submit">
                                    Add
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                </>
            );
        }

        let no = 1;
        return (
            <>
                <AddItems/>
                <DeleteItemClass/>
                <Modal show={showAddClassItem} backdrop="static" size="xl" onHide={() => setShowAddClassItem(false)}>
                    <Modal.Header>
                        <div className="row" style={{width: "100vw"}}>
                            <div className="ml-right col-md-12">
                                <h4> Add Class Room </h4>
                            </div>

                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12">
                            <table className="table table-bordered">
                                <tbody>
                                <tr>
                                    <td style={{width: "50%"}}>Branch name</td>
                                    <td><span className="text-info">{property.branch_name}</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <Row>
                            <Col md="12">
                                <div className="card-user">

                                    <CardBody>
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setShowAddItems(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                        <div style={{height: '350px', overflowY: 'scroll'}}>
                                            <Table>
                                                <thead className="text-primary">
                                                <tr>
                                                    <th style={{width: '30%'}}>No</th>
                                                    <th style={{width: '70%'}}>Classroom Name</th>
                                                    <th className="text-right">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {classroomlist.map((e) => (
                                                    <tr key={e.id}>
                                                        <td>{no++}</td>
                                                        <td style={{width: "33%"}}>{e.name}</td>

                                                        <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="outline-info"
                                                                                 id="dropdown-basic">
                                                                    <span className="nc-icon nc-bullet-list-67"/>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Button title="Update & Detail View Record"
                                                                            onClick={async () => {
                                                                                await setShowAddItems(true);
                                                                                document.getElementById('classroom_name').value = e.name;
                                                                                document.getElementById('class_id').value = e.id;
                                                                            }} className="btn-round dropdownlist"
                                                                            variant="light" type="Button">
                                                                        <span
                                                                            className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                                        Update & Detail View Record
                                                                    </Button>
                                                                    <br/>
                                                                    <Button title="Delete Record" onClick={() => {
                                                                        setRecordClassId(e.id);
                                                                        setShowClassItmeDeleteModal(true);
                                                                    }} className="btn-round dropdownlist"
                                                                            variant="light" type="Button"
                                                                    >
                                                                        <span
                                                                            className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                                        Delete Record
                                                                    </Button>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button title="Close"
                                className="btn-simple"
                                type="button"
                                variant="link"
                                onClick={() => setShowAddClassItem(false)}>
                            Close
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }

    function AddItem() {
        return (
            <>
                <Modal show={showModal} backdrop="static" size="xl" onHide={() => setShowModal(false)}>
                    <Form onSubmit={(e) => addBranch(e)}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                                New Branch
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Input hidden={true} required name="branch_id" id="branch_id" value="0" type="hidden"/>

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Branch name <span className="text text-danger">*</span></label>
                                        <Input required name="branch_name" id="branch_name"
                                               placeholder="Branch name" title="Branch name" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Address <span className="text text-danger">*</span></label>
                                        <Input required name="branch_address" id="branch_address"
                                               placeholder="Address" title="Address" type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="pr-3">

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Phone <span className="text text-danger">*</span></label>
                                        <Input required name="branch_phone" id="branch_phone"
                                               placeholder="Phone" title="Phone" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Email <span className="text text-danger">*</span></label>
                                        <Input required name="branch_email" id="branch_email"
                                               placeholder="Email" title="Email" type="email"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label>Description <span className="text text-danger">*</span></label>
                                        <textarea className="form-control" placeholder="description . . ."
                                                  name="branch_description" id="branch_description"></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <DeleteItem/>
                <AddClassItem/>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        {/*<div className="ml-right">*/}
                                        {/*    <h5> Branch List </h5>*/}
                                        {/*</div>*/}
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setShowModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Branch Name</th>
                                        <th>Address</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "30%"}}>{e.name}</td>
                                            <td style={{width: "70%"}}>{e.address}</td>

                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Update & Detail View Record"
                                                                onClick={async () => {
                                                                    await setShowModal(true);
                                                                    console.log(e);
                                                                    document.getElementById('branch_name').value = e.name;
                                                                    document.getElementById('branch_phone').value = e.phone;
                                                                    document.getElementById('branch_email').value = e.email;
                                                                    document.getElementById('branch_description').value = e.description;
                                                                    document.getElementById('branch_address').value = e.address;
                                                                    document.getElementById('branch_id').value = e.id;
                                                                }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record"
                                                                onClick={() => {
                                                                    setProperty({
                                                                        "branch_id": e.id,
                                                                        "branch_name": e.name,
                                                                        "address": e.address,
                                                                        "description": e.description,
                                                                        "phone": e.phone,
                                                                        "email": e.email
                                                                    });
                                                                    setRecordId(e.id);
                                                                    setShowAddClassItem(true);
                                                                }}
                                                                className="btn-round dropdownlist" variant="light"
                                                                type="Button"
                                                        >
                                                            <span className="nc-icon nc-album-2 text-info"/>&nbsp;&nbsp;
                                                            Add Classroom
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
