import "./../../assets/css/bootstrap.min.css"
import React, {useEffect, useState} from "react";
import {Col,Row} from "react-bootstrap";
import {CardHeader, CardTitle, Input, Card} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import toast, {Toaster} from 'react-hot-toast';
import AddAccount from "./AddAccount";
import ListAccount from "./ListAccount";
import SearchAccount from "./SearchAccount";

function Account() {


    const [subAdminStatus, setSubAdminStatus] = useState(1);

    const [departmentList, setDepartmentList] = React.useState([]);
    const [branchList, setBranchList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [coaList, setCoaList] = useState([]);
    const [coaElementList, setCoaElementList] = useState([]);
    const [unitTypeList, setUnitTypeList] = useState([]);
    const [vendorList, setVendorList] = useState([]);

    const [list, setList] = React.useState([]);
    const [dataForm, setDataForm] = React.useState([]);
    const [attachFile, setAttachFiles] = React.useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [localImageClass, setLocalImageClass] = useState("");

    const [updateRecordStatus, setUpdateRecordStatus] = React.useState(false);
    const [needObject, setNeedObject] = React.useState({'project_id':0,'province_id':0,'branch_id':0,'class_id':0});
    const [searchParameter, setSearchParameter] = React.useState({})
    const [searchStatus, setSearchStatus] = React.useState(false);


    useEffect(() => {
        loadData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    function getBranchs() {
        return Axios.get(mainUrl + "getBranch",{headers});
    }
    function getAccounts() {
        return Axios.get(mainUrl + "getInvoice?page=1",{headers});
    }
    function getUnitType() {
        return Axios.get(mainUrl + "getUnitType",{headers});
    }
    function getYear() {
        return Axios.get(mainUrl + "getYear",{headers});
    }
    function getVendor() {
        return Axios.get(mainUrl + "getVendor",{headers});
    }
    function getCoa() {
        return Axios.get(mainUrl + "getCoa",{headers});
    }
    function getAllCoaElement() {
        return Axios.get(mainUrl + "getAllCoaElement",{headers});
    }

    function getDepartment() {
        return Axios.get(mainUrl + "getDepartment",{headers});
    }

    const loadData= async()=>{
        loadParameters();
        await Promise.all([getAccounts()])
            .then(function (results) {
                setList(results[0].data);
                setIsLoading(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });;
    }

    const loadParameters= async()=>{
        await Promise.all([getUnitType(),getVendor(),getCoa(),getAllCoaElement(),getBranchs(),getYear(),getDepartment()])
            .then(function (results) {

                setUnitTypeList(results[0].data);
                setVendorList(results[1].data);
                setCoaList(results[2].data);
                setCoaElementList(results[3].data);
                setBranchList(results[4].data);
                setYearList(results[5].data);
                setDepartmentList(results[6].data);

            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });;
    }

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">
                <div>
                    <Row>
                        <Col md="12">
                            <Card className="card-user">
                                <CardHeader style={{backgroundColor: "#80808029"}}>
                                    <CardTitle className="mr-4 ml-4">
                                        <div className="row">
                                            <div className="ml-right">
                                                <h3>Accounting</h3>
                                            </div>
                                        </div>
                                    </CardTitle>
                                </CardHeader>
                                <SearchAccount yearList={yearList}
                                                searchStatus={searchStatus}
                                                setSearchStatus={setSearchStatus}
                                                searchParameter={searchParameter}
                                                setSearchParameter={setSearchParameter}
                                                setLocalImageClass={setLocalImageClass} localImageClass={localImageClass} isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal} setShowModal={setShowModal} setList={setList} branchList={branchList}
                                              headers={headers} notifyError={notifyError} notifySuccess={notifySuccess}/>
                            </Card>
                        </Col>
                    </Row>
                    <AddAccount
                        branchList={branchList}
                        yearList={yearList}
                        coaList={coaList}
                        coaElementList={coaElementList}
                        unitTypeList={unitTypeList}
                        vendorList={vendorList}
                        departmentList={departmentList}

                        setDataForm={setDataForm}
                        dataForm={dataForm}
                        setNeedObject={setNeedObject}
                        needObject={needObject}
                        setUpdateRecordStatus={setUpdateRecordStatus}
                        updateRecordStatus={updateRecordStatus}
                        subAdminStatus={subAdminStatus}
                        setSubAdminStatus={setSubAdminStatus}
                        setAttachFiles={setAttachFiles}
                        attachFile={attachFile}
                        setLocalImageClass={setLocalImageClass}
                        localImageClass={localImageClass}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        setList={setList}
                        list={list}
                        headers={headers} notifyError={notifyError} notifySuccess={notifySuccess}/>
                    <ListAccount
                        setDataForm={setDataForm}
                        searchStatus={searchStatus}
                        setSearchStatus={setSearchStatus}
                        searchParameter={searchParameter}
                        setSearchParameter={setSearchParameter}
                        setNeedObject={setNeedObject}
                        needObject={needObject}
                        setUpdateRecordStatus={setUpdateRecordStatus}
                        updateRecordStatus={updateRecordStatus}
                        subAdminStatus={subAdminStatus}
                        setSubAdminStatus={setSubAdminStatus}
                        setAttachFiles={setAttachFiles}
                        attachFile={attachFile}
                        setLocalImageClass={setLocalImageClass}
                        localImageClass={localImageClass}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        setList={setList}
                        list={list}
                        headers={headers} notifyError={notifyError}
                                 notifySuccess={notifySuccess}/>
                </div>
            </div>
        </>
    );


}


export default Account;
