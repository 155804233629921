
import Dashboard from "views/Dashboard.js";
import Settings from "views/Setting/Settings.js";
import Classes from "views/Class/Class";
import Students from "views/Student/Student";
import Employees from "views/Employee/Employee";
import UserPage from "views/Profile/Profile.js";
import Purchase from "views/Purchase/Purchase";
import Accounting from "views/Accounting/Account";
import Reports from "views/Report/Reports.js";

var routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    title:'A Dashboard is a management information system (MIS) that allows executives and other stakeholders to define targets and monitor progress of key performance indicators (KPI).',
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "",
  },
  {
    path: "/profile",
    name: "userProfile",
    title:'A user profile is a collection of settings and information associated with a user.',
    icon: "nc-icon nc-circle-10",
    component: UserPage,
    layout: "",
  },
  {
    path: "/Class",
    name: "Class",
    title:'setting is an adjustment in a software program that changes it to the user\'s preference. ',
    icon: "nc-icon nc-layout-11",
    component: Classes,
    layout: "",
  },
  {
    path: "/Employee",
    name: "Employee",
    title:'setting is an adjustment in a software program that changes it to the user\'s preference. ',
    icon: "nc-icon nc-single-02",
    component: Employees,
    layout: "",
  },
  {
    path: "/Student",
    name: "Student",
    title:'setting is an adjustment in a software program that changes it to the user\'s preference. ',
    icon: "nc-icon nc-hat-3",
    component: Students,
    layout: "",
  },
  {
    path: "/Purchase",
    name: "purchasing",
    title:'setting is an adjustment in a software program that changes it to the user\'s preference. ',
    icon: "nc-icon nc-cart-simple",
    component: Purchase,
    layout: "",
  },
  {
    path: "/Accounting",
    name: "accounting",
    title:'setting is an adjustment in a software program that changes it to the user\'s preference. ',
    icon: "nc-icon nc-chart-bar-32",
    component: Accounting,
    layout: "",
  },
  {
    path: "/Setting",
    name: "asset",
    title:'setting is an adjustment in a software program that changes it to the user\'s preference. ',
    icon: "nc-icon nc-money-coins",
    component: Settings,
    layout: "",
  },
  {
    path: "/Setting",
    name: "setting",
    title:'setting is an adjustment in a software program that changes it to the user\'s preference. ',
    icon: "nc-icon nc-settings",
    component: Settings,
    layout: "",
  },
  {
    path: "/reports",
    name: "report",
    title:'Reporting is a key process in any organization and for every kind of project',
    icon: "nc-icon nc-book-bookmark",
    component: Reports,
    layout: "",
  }
];
export default routes;
