import React, {useEffect, useState} from "react";
import ImageSelect from "./utils/imageSelect";
import {Button, Col, FormGroup, Modal, Row, Spinner} from "react-bootstrap";
import image from "../../assets/img/image.png";
import { Input} from "reactstrap";
import Axios from "axios";
import {mainUrl, toBase64, dataURLtoFile} from "../../common/common.js";
import 'react-phone-number-input/style.css';

function AddEmployee(props) {

    const [showUploadImage, setShowUploadImage] = useState(false);
    const [downloadStatus, setDownloadStatus] = useState(false);
    useEffect(() => {
        setTimeout(() => props.setLocalImageEmployee(sessionStorage.getItem("image")), 1000);
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addEmployee = async (e) => {
        e.preventDefault();
        const f = e.target;
        let attachFile = "";
        if (f.attachment.files[0] != null && f.attachment.files[0] != "") {
            attachFile = await toBase64(f.attachment.files[0]);
        }
        await Axios.post(mainUrl + "addStaff", {
            id: f.ids.value,
            employee_id: f.employee_ids.value,
            name: f.name.value,
            father_name: f.father_name.value,
            email: f.email.value,
            phone: f.phone.value,
            gender_id: f.gender_id.value,
            branch_id: f.branch_id.value,
            position_id: f.position_id.value,
            salary: f.salary.value,
            attachment: attachFile,
            remark: f.remark.value,
            status_id: f.status_id.value,
            photo: f.image.value,

        }, {headers}).then(res => {
            if (f.ids.value == 0) {
                props.notifySuccess(' Added Successfully.');
            } else {
                props.notifySuccess(' Updated Successfully.');
            }
            document.querySelector(".main-panel")
                .scrollTo(0, 0);
            props.setList(res.data);
            props.setShowModal(false);
            props.setUpdateRecordStatus(false);
        }).catch(function (error) {
            props.notifyError(error.response.data.message);
        });
    };

    const downloadFile = async () => {
        setDownloadStatus(true);
        const f = document.getElementById('ids').value;
        await Axios.post(mainUrl + "getAttachFile", {
            employee_id: f
        }, {headers}).then(res => {
            var filePDf = dataURLtoFile('data:application/x-zip-compressed;base64,' + res.data, 'attachment.zip');
            const previewUrl = window.URL.createObjectURL(filePDf);
            const anchor = document.createElement("a");
            anchor.download = "attachment.zip";
            anchor.href = URL.createObjectURL(filePDf);

            anchor.click();
            window.URL.revokeObjectURL(previewUrl);
        }).catch(function (error) {
            props.notifyError(error.response.data.message);
        });
        setDownloadStatus(false);
    }
    return (
        <>
            {showUploadImage == true && <ImageSelect setLocalImageEmployee={props.setLocalImageEmployee}/>}
            <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
                <form onSubmit={(e) => addEmployee(e)}>
                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile">
                            New Employee
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <div className=" btn"
                                 style={{position: "relative", textAlign: "center", color: "white"}} alt="Image "
                                 title="Image">
                                <img src={props.localImageEmployee != "" ? props.localImageEmployee : image}
                                     style={{borderRadius: "50%", backgroundColor: "#8080805e!important"}}
                                     onClick={() => {
                                         if (!showUploadImage) {
                                             setShowUploadImage(true);
                                         } else {
                                             setShowUploadImage(false);
                                         }
                                     }} width="125" height="125"/>
                                <div style={{
                                    position: "absolute",
                                    top: "89%",
                                    left: "50%",
                                    transform: "translate(-50%, 50%)",
                                    whiteSpace: "nowrap"
                                }} className="text-primary">Select Image
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="image" id="image" value={props.localImageEmployee}/>
                        <div style={{height: '500px', overflowY: 'auto', marginTop: '20px', paddingBottom: '50px   '}}>
                            <Row className="pr-3">
                                <Col md="3">
                                    <Input type="hidden" name="ids" id="ids" defaultValue={props.dataForm.id}/>
                                    <FormGroup  className={props.dataForm.id==null && "visually-hidden"} >
                                        <label>Employee ID</label>
                                        <Input disabled  name="employee_ids" id="employee_ids" value={"EMP-"+("0000" + props.dataForm.id).slice(-5)}
                                               placeholder="Employee Id" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <label>Branch <span className="text text-danger">*</span></label>
                                        <select required name="branch_id" id="branch_id" required
                                                className="form-control" title="Branch" defaultValue={props.dataForm.branch_id}>
                                            <option value=""></option>
                                            {props.branchList.map((e) => (
                                                <option key={e.id}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Name <span className="text text-danger">*</span></label>
                                        <Input required name="name" id="name" required placeholder="name" defaultValue={props.dataForm.name}
                                               title="name" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Father Name <span className="text text-danger">*</span></label>
                                        <Input required name="father_name" id="father_name"
                                               placeholder="Father Name" title="Father Name" defaultValue={props.dataForm.fname}
                                               type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label> Email address <span
                                            className="text text-danger">*</span></label>
                                        <Input required name="email" id="email" placeholder="Email Address" defaultValue={props.dataForm.email}
                                               type="email" title="Email Address"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label> Phone Number <span className="text text-danger">*</span></label>
                                        <Input type="tel"
                                               required
                                               name="phone" id="phone"
                                               placeholder="Phone Number" defaultValue={props.dataForm.phone}
                                               pattern="[1-9]{1}[0-9]{8}"/>
                                               <small className="badge badge-default"> Format: 700123456 </small>
                                        <br/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="3">
                                    <FormGroup>
                                        <label>Gender <span className="text text-danger">*</span></label>
                                        <select required name="gender_id" id="gender_id" required defaultValue={props.dataForm.gender_id}
                                                className="form-control" title="Gender">
                                            <option value=""></option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                        </select>

                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="3">
                                    <FormGroup>
                                        <label>Status <span className="text text-danger">*</span></label>
                                        <select required name="status_id" id="status_id" required defaultValue={props.dataForm.status}
                                                className="form-control" title="Status Type">
                                            <option value="1">Active</option>
                                            <option value="2">DeActive</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="3">
                                    <FormGroup>
                                        <label> Salary <span className="text text-danger">*</span></label>
                                        <Input required name="salary" id='salary' placeholder="Salary" type="Number" defaultValue={props.dataForm.salary}
                                               title="Salary"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="3">
                                    <FormGroup>
                                        <label>Position <span className="text text-danger">*</span></label>
                                        <select required name="position_id" id='position_id' defaultValue={props.dataForm.position_id}
                                                className="form-control" title="Select position">
                                            <option value=""></option>
                                            {props.positionList.map((e) => (
                                                <option key={e.id}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="3">
                                    <FormGroup>
                                        <label>Attachment <span className="text text-danger">*</span></label>
                                        <input name="attachment" id='attachment' accept=".zip" type="file"
                                               title="Attach File"
                                               className="form-control"/>
                                        Select pdf file only.<br/>
                                        Maximum upload file size :5 MB
                                    </FormGroup>
                                    {props.attachFile == true &&
                                    <button className="btn btn-warning" onClick={() => downloadFile()}>
                                        {downloadStatus === true ? ("Loading . . . ") : ("Download File")}
                                    </button>
                                    }
                                </Col>
                                <Col className="pr-3" md="9">
                                    <FormGroup>
                                        <label>Remark <span className="text text-danger">*</span></label>
                                        <textarea className="form-control" placeholder="Remark . . ." name="remark" id="remark" defaultValue={props.dataForm.remark}></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                            title="Close"
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() =>{
                                props.setUpdateRecordStatus(false); props.setShowModal(false)}}>
                            Close
                        </Button>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                            Submit
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
}

export default AddEmployee;
