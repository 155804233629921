import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Modal, Row, Spinner, Table, Dropdown} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";

function ListEmployee(props) {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        setRecordList(props.list.data);
    })

    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteStaff", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Successfully deleted");
            const lists = res.data;
            props.setList(lists);
        }).catch((error) => {
            props.notifyError(error.response.data.message);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }
    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchStaff?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getStaff?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }
        setRecordId(0);
        setShowDeleteModal(false);
    }

    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    let rowNo = 1;
    return (
        <>
            <DeleteItem/>
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-end">
                                        <Button title="Add New Record" className="btn-success" variant="primary"
                                                onClick={() => {
                                                    props.setDataForm({})
                                                    props.setAttachFiles(false);
                                                    props.setShowModal(true);
                                                    props.setSubAdminStatus(1);
                                                    props.setLocalImageEmployee("");
                                                    sessionStorage.setItem("image", "");
                                                }}>
                                            <span className="nc-icon nc-simple-add"/>
                                        </Button>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>

                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Employee Id</th>
                                        <th>Employee Name</th>
                                        <th>Phone</th>
                                        <th>Branch</th>
                                        <th>Position</th>
                                        <th>Status</th>
                                        <th className="text-right ">Action</th>
                                    </tr>
                                    </thead>
                                    {props.isLoading === false ? (
                                        <tbody>
                                        <tr>
                                            <td colSpan="9" style={{textAlign: "center"}}>
                                                <Spinner animation="border" variant="info"/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    ) : (
                                    <tbody>

                                    {recordList.map((e, index) => (
                                        <tr key={index}>
                                            <td>{rowNo++}</td>
                                            <td style={{width: "10%"}}>({"EMP-"+("0000"+e.id).slice(-5)}) </td>
                                            <td style={{width: "20%"}}> {e.name}</td>
                                            <td style={{width: "20%"}}>{e.phone}</td>
                                            <td style={{width: "20%"}}>{e.branch_name}</td>
                                            <td style={{width: "20%"}}>{e.position_name}</td>
                                            <td style={{width: "10%", whiteSpace: "nowrap"}}>
                                                {e.status === 1 &&
                                                <span className="badge badge-primary ">Active</span>
                                                }
                                                {e.status === 2 &&
                                                <span className="badge badge-warning ">Deactive</span>
                                                }
                                            </td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Update & Detail View Record"
                                                                style={{whiteSpace: "nowrap"}}
                                                                className="btn-round" variant="light" type="Button"
                                                                onClick={async () => {
                                                                    props.setDataForm(e);
                                                                    await props.setShowModal(true);
                                                                    setRecordId(e.id);
                                                                    props.setUpdateRecordStatus(true);
                                                                }}>
                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <Button title="Update & Detail View Record"
                                                                className="btn-round dropdownlist " variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setRecordId(e.id);
                                                                    setShowDeleteModal(true);
                                                                }}>
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                        )}
                                </Table>
                                <div className="page-content-center">
                                    {loadMore === true ? (
                                            <div className="text-center">
                                                <Spinner animation="border" variant="info"/>
                                            </div>
                                        ) :
                                        (props.list.last_page >= pageNo ?
                                            <button className="btn  bg-light text-info" title="Load More"
                                                onClick={(pageNumber => {
                                                    nextPageLoadList(pageNumber);
                                                })}>
                                                <span className="nc-icon nc-simple-add text-info"/>
                                            </button>

                                        :"")}
                                </div>
                            </CardBody>

                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListEmployee;
