
const englishWords= {
    "totalAdminUser": "Total User",
    "mis_name": "Atarud Management System",
    "dashboard": "Dashboard",
    "userProfile": "User Profile",
    "setting": "Setting",
    "report": "Report"
}


export default englishWords;
