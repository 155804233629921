import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Modal, Row, Spinner, Table, Dropdown} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";

// import Pagination from "@material-ui/lab/Pagination";
// import Dropdown from "bootstrap/js/src/dropdown";

function ListClass(props) {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showBlockModal, setShowBlockModal] = React.useState(false);
    const [accountStatus, setAccountStatus] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        if (props.list.data) {
            setRecordList(props.list.data);
        }
    })

    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteClass", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Successfully deleted");
            const lists = res.data;
            props.setList(lists);
        }).catch((error) => {
            props.notifyError(error.response.data.message);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }
    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchClass?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getClass?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }

        setRecordId(0);
        setShowDeleteModal(false);
    }

    const onBlockRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "blockAccount", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Account Status has been changed!");
            for (const i in props.list.data) {
                if (props.list.data[i].id == recordId) {
                    if (props.list.data[i].is_active == 1) {
                        props.list.data[i].is_active = 0;
                    } else {
                        props.list.data[i].is_active = 1;
                    }
                    break;
                }
            }
            setShowBlockModal(false);
        }).catch((error) => {
            props.notifyError(error.response.data.message);

            setShowBlockModal(false);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }

    const convertTime24to12 = (time24h) => {
        let time = time24h
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time24h];

        if (time.length > 1) {
            time = time.slice(1, -1);
            time[5] = +time[0] < 12 ? ' AM' : ' PM';
            time[0] = +time[0] % 12 || 12;
        }
        return time.join('');
    };

    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-class">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function BlockItem() {
        return (
            <>
                <Modal show={showBlockModal} backdrop="static" size="lg" onHide={() => setShowBlockModal(false)}>
                    <form onSubmit={(e) => onBlockRecord(e)}>
                        <Modal.Header>
                            <div className="modal-class">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to <span
                                    className="badge badge-primary">{accountStatus}</span> this Account?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowBlockModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    let rowNo = 1;
    return (
        <>
            <DeleteItem/>
            <BlockItem/>
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    {/*<div className="ml-right">*/}
                                    {/*    <h2> User Class </h2>*/}
                                    {/*</div>*/}
                                    <div className="ml-end">
                                        <Button title="Add New Record" className="btn-success" variant="primary"
                                                onClick={() => {
                                                    props.setDataForm({});
                                                    props.setAttachFiles(false);
                                                    props.setShowModal(true);
                                                    props.setSubAdminStatus(1);
                                                    props.setLocalImageClass("");
                                                    sessionStorage.setItem("image", "");
                                                }}>
                                            <span className="nc-icon nc-simple-add"/>
                                        </Button>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>

                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Class Code</th>
                                        <th>Year</th>
                                        <th>Branch Name</th>
                                        <th>Department</th>
                                        <th>Program</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Status</th>
                                        <th className="text-right ">Action</th>
                                    </tr>
                                    </thead>
                                    {props.isLoading === false ? (
                                        <tbody>
                                        <tr>
                                            <td colSpan="9" style={{textAlign: "center"}}>
                                                <Spinner animation="border" variant="info"/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>

                                    {recordList.map((e, index) => (
                                        <tr key={index}>
                                            {/*className={rowNo % 2 == 0 ? "bg-dark bg-opacity-10" : ""}*/}
                                            <td>{rowNo++}</td>
                                            <td style={{width: "10%"}}> (CLS-{("0000" + e.id).slice(-5)})</td>
                                            <td style={{width: "15%"}}> {e.year_name}</td>
                                            <td style={{width: "15%"}}> {e.branch_name}</td>
                                            <td style={{width: "15%"}}>{e.department_name}</td>
                                            <td style={{width: "10%"}}>{e.program_name}</td>
                                            <td style={{width: "10%"}}>{e.start_date}</td>
                                            <td style={{width: "10%"}}>{convertTime24to12(e.start_time)} -  {convertTime24to12(e.end_time)} </td>
                                            <td style={{width: "10%"}}>
                                                {(e.status === 2) && <span className="badge badge-danger ">DeActive</span>}
                                                {(e.status === 1) && <span className="badge badge-success ">Active</span>}
                                            </td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Update & Detail View Record"
                                                                style={{whiteSpace: "nowrap"}}
                                                                className="btn-round" variant="light" type="Button"
                                                                onClick={async () => {
                                                                    props.setDataForm(e);
                                                                    await props.setShowModal(true);
                                                                    setRecordId(e.id);
                                                                    props.setAttachFiles(false);
                                                                    props.setSubAdminStatus(e.user_type_id);
                                                                    if (e.attachment != null && e.attachment != "") {
                                                                        props.setAttachFiles(true);
                                                                    }

                                                                    props.setNeedObject({
                                                                        'project_id': e.project_id,
                                                                        'province_id': e.province_id,
                                                                        'branch_id': e.branch_id,
                                                                        'class_id': e.class_id
                                                                    });
                                                                    props.setUpdateRecordStatus(true);
                                                                }}>

                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <Button title="Update & Detail View Record"
                                                                className="btn-round dropdownlist " variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setRecordId(e.id);
                                                                    setShowDeleteModal(true);
                                                                }}>
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                        {/*<Button title="   Block & Unblock Account"*/}
                                                        {/*        className="btn-round dropdownlist" variant="light"*/}
                                                        {/*        type="Button"*/}
                                                        {/*        onClick={() => {*/}
                                                        {/*            setRecordId(e.id);*/}
                                                        {/*            if (e.is_active === 0) {*/}
                                                        {/*                setAccountStatus('Unblock');*/}
                                                        {/*            } else {*/}
                                                        {/*                setAccountStatus('Block');*/}
                                                        {/*            }*/}
                                                        {/*            setShowBlockModal(true);*/}
                                                        {/*        }}>*/}

                                                        {/*    <span className="nc-icon nc-trophy text-info"/> &nbsp;&nbsp;*/}
                                                        {/*   Promote*/}
                                                        {/*</Button>*/}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                        )}
                                </Table>
                                <div className="page-content-center">
                                    {loadMore === true ? (
                                            <div className="text-center">
                                                <Spinner animation="border" variant="info"/>
                                            </div>
                                        ) :
                                        (props.list.last_page >= pageNo ?
                                            <button className="btn  bg-light text-info" title="Load More"
                                                    onClick={(pageNumber => {
                                                        nextPageLoadList(pageNumber);
                                                    })}>
                                                <span className="nc-icon nc-simple-add text-info"/>
                                            </button>

                                            : "")}
                                </div>
                            </CardBody>

                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListClass;
