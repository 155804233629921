import React, {useEffect, useState} from "react";

import {Button, Col, FormGroup, Modal, Row, Spinner} from "react-bootstrap";
import image from "../../assets/img/image.png";
import {Card, Input} from "reactstrap";
import Axios from "axios";
import {mainUrl, toBase64, dataURLtoFile} from "../../common/common.js";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import PurchaseItem from "./PurchaseItem/PurchaseItem";


function AddPurchase(props) {

    const [elementList, setElementList] = useState([]);
    const [statusRecord, setStatusRecord] = useState(true);

    useEffect(() => {
        if (props.dataForm.id) {
            setStatusRecord(false)
        } else {
            setStatusRecord(true)
        }
    });

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addPurchase = async (e) => {
        e.preventDefault();
        const f = e.target;
        await Axios.post(mainUrl + "addPurchase", {
            id: f.ids.value,
            date: f.date.value,
            remark: f.remark.value,
            coa_id: f.coa_id.value,
            element_id: f.element_id.value,
            branch_id: f.branch_id.value,
            year_id: f.year_id.value,
            vendor_id: f.vendor_id.value
        }, {headers}).then(res => {
            if (f.ids.value == 0) {
                props.notifySuccess(' Added Successfully.');
                (props.list.data.unshift(res.data));
            } else {
                props.notifySuccess(' Updated Successfully.');
            }
            document.querySelector(".main-panel")
                .scrollTo(0, 0);
            props.setDataForm(res.data);
            setStatusRecord(false);
            props.setUpdateRecordStatus(false);
        }).catch(function (error) {
            props.notifyError(error.response.data.message);
        });
    };

    const onCoAChange = async (e) => {
        // e.preventDefault();
        let newArray = props.coaElementList.filter(function (el) {
            return el.coa_id == e.target.value;
        }).map(a => a.id);
        let res = props.coaElementList.filter(function (el) {
            return newArray.includes(el.id);
        })
        setElementList(res);
    };
    var startDate = new Date();
    startDate.setDate(startDate.getDate()-30);
    var currentDate= startDate.toISOString().substr(0, 10);

    return (
        <>
        <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
            <Modal.Header className="justify-content-center">
                <div className="modal-profile">
                    Add or Modify Purchase
                </div>
            </Modal.Header>
            <Modal.Body>
                <div style={{height: '500px', overflowY: 'auto', marginTop: '20px', paddingBottom: '50px   '}}>
                    <form onSubmit={(e) => addPurchase(e)}>
                        <Row className="pr-3">
                            <Col md="6">
                                <Input type="hidden" className="" name="ids" id="ids"
                                       defaultValue={props.dataForm.id}/>
                                <FormGroup className={props.dataForm.id==null && "visually-hidden"}>
                                    <label>Purchase Code</label>
                                    <Input  readOnly={true} name="purchase_code" id="purchase_code"
                                             value={("PUR-"+("0000"+props.dataForm.id).slice(-5))}
                                             placeholder="Purchase Code" type="text"/>

                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Date</label>
                                    <Input name="data" id="date" defaultValue={props.dataForm.date}
                                           // defaultValue={currentDate}
                                           placeholder="date" type="date"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="pr-3">
                            <Col md="4">
                                <FormGroup>
                                    <label> Year <span className="text text-danger">*</span></label>
                                    <select title="Year" name="year_id" id='year_id'
                                            className="form-control" defaultValue={props.dataForm.year_id}>
                                        {
                                            props.yearList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col><Col md="4">
                            <FormGroup>
                                <label> Branch <span className="text text-danger">*</span></label>
                                <select title="Year" name="branch_id" id='branch_id'
                                        className="form-control" defaultValue={props.dataForm.branch_id}>
                                    {
                                        props.branchList.map((e, index) => (
                                            <option key={index}
                                                    value={e.id}>{e.name}</option>))
                                    }
                                </select>
                            </FormGroup>
                        </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>Vendor <span className="text text-danger">*</span></label>
                                    <select title="Select Branch" name="vendor_id" id='vendor_id'
                                            className="form-control" defaultValue={props.dataForm.vendor_id}>
                                        <option value=""></option>
                                        {
                                            props.vendorList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.company_name}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>Chart of Account <span className="text text-danger">*</span></label>
                                    <select title="Select Chart of Account" name="coa_id" id='coa_id'
                                            onChange={e => onCoAChange(e)}
                                            className="form-control" defaultValue={props.dataForm.coa_id}>
                                        <option value=""></option>
                                        {
                                            props.coaList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>Element<span className="text text-danger">*</span></label>
                                    <select title="Select Element" name="element_id" id='element_id'
                                            className="form-control" defaultValue={props.dataForm.element_id}>
                                        {
                                            elementList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>Remark<span className="text text-danger">*</span></label>
                                    <textarea className="form-control" placeholder="Remark . . ." name="remark"
                                              id="remark" defaultValue={props.dataForm.remark}></textarea>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                            Save
                        </Button>
                    </form>
                        <PurchaseItem statusRecord={statusRecord} headers={headers} branchId={props.dataForm.branch_id} purchaseId={props.dataForm.id}
                                 classId={props.dataForm.id} teacherList={props.teacherList} unitTypeList={props.unitTypeList}/>
                </div>
            </Modal.Body>
            <div className="modal-footer">
                <Button
                    title="Close"
                    className="btn-simple"
                    type="button"
                    variant="link"
                    onClick={() => {
                        props.setUpdateRecordStatus(false);
                        props.setShowModal(false)
                    }}>
                    Close
                </Button>

            </div>
        </Modal>
</>
)
    ;
}

export default AddPurchase;
