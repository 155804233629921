import React, {useEffect, useState} from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import {Spinner} from "react-bootstrap"
import ATARUD from "./../assets/img/atarudpng.jpg";
import ARIADELTA from "./../assets/img/aria_delta.png";
import {useTranslation} from 'react-i18next';

import Axios from "axios";
import {mainUrl} from "common/common.js";
import i18n from "i18next";
import PieChart from "./chart/PieChart";

function Dashboard() {
    const {t, i18n} = useTranslation();


    const [isLoading, setIsLoading] = useState(true);
    const [totalUser, setTotalUser] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalProject, setTotalProject] = useState(0);
    const [totalProvince, setTotalProvince] = useState(0);
    const [totalBranch, setTotalBranch] = useState(0);
    const [property, setProperty] = useState([]);
    const [title, setTitle] = useState("");

    const [dataChart, setDataChart] = useState([122, 12]);
    const [colorChart, setColorChart] = useState(["#ffc107", "#007bff"]);

    useEffect(() => {
        branchAnalysis();
        provinceAnalysis();
        projectAnalysis();
        userAnalysis();
    }, []);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const colorGenerator =(n)=>{
       var  color=[];
        for (let v = 0; v < n; v++) {
            var rgb = [];
            for (var i = 1; i < 4; i++)
                rgb.push(Math.floor(Math.random() * 255));
            color[v] = 'rgb(' + rgb.join(',') + ')';
        }
        return color;
    }

    const userAnalysis = async () => {
        await Axios.get(mainUrl + "userStatus", {headers}).then(res => {
            const value = [];
            const prop=[];
            let count=0;
            res.data.map((e) => {
                if (e.user_type_id == 1) {
                    count=count+e.total;
                    value[0] = e.total;
                    prop[0]='AppUser'
                } else if (e.user_type_id == 2) {
                    value[1] = e.total;
                    count=count+e.total;
                    prop[1]='Sub Admin'
                } else if (e.user_type_id == 3) {
                    value[2] = e.total;
                    count=count+e.total;
                    prop[2]='Super Admin'
                }
            });

            var color = colorGenerator(value.length);
            // const prop = ['AppUser', 'Super Admin', 'Sub Admin'];
            let pr = [];
            for (let v = 0; v < value.length; v++) {
                pr[v] = <div> {color[v]} {prop[v]} {value[v]}</div>
            }
            setTotalUser(count);
            setTotalUsers(count);
            setDataChart(value);
            setProperty(pr);
            setTitle("User Status ");
            setColorChart(color);

            setIsLoading(true);
        });
    }
    const projectAnalysis = async () => {
        await Axios.get(mainUrl + "projectStatus", {headers}).then(res => {
            const value = [];
            const prop = [];
            let count=0;
            res.data.map((e, index) => {
                count=count + e.total;
                value[index] = e.total;
                prop[index] = e.project_name;
            });
            var color = colorGenerator(value.length);
            let pr = [];
            for (let v = 0; v < value.length; v++) {
                pr[v] = <div> {color[v]} {prop[v]} {value[v]}</div>
            }

            setTotalUsers(count);
            setTotalProject(value.length);
            setDataChart(value);
            setProperty(pr);
            setTitle("Project Status ");
        });
    }
    const provinceAnalysis = async () => {
        await Axios.get(mainUrl + "provinceStatus", {headers}).then(res => {
            const value = [];
            const prop = [];
            let count=0;
            res.data.map((e,index) => {
                count=count+e.total;
                value[index]=e.total;
                prop[index]=e.province_name;
            });
            var color = colorGenerator(value.length);
            let pr=[] ;
            for(let v=0;v<value.length;v++){
                pr[v]=<div> {color[v]} {prop[v]} {value[v]}</div>
            }

            setTotalUsers(count);
            setTotalProvince(value.length);
            setDataChart(value);
            setProperty(pr);
            setTitle("Province Status ");
        });
    }
    const branchAnalysis = async () => {
        await Axios.get(mainUrl + "branchStatus", {headers}).then(res => {
            const value = [];
            const prop = [];
            let count=0;
            res.data.map((e,index) => {
                count=count+e.total;
                value[index]=e.total;
                prop[index]=e.branch_name;
            });
            var color = colorGenerator(value.length);
            let pr=[] ;
            for(let v=0;v<value.length;v++){
                pr[v]=<div> {color[v]} {prop[v]} {value[v]}</div>
            }

            setTotalUsers(count);
            setTotalBranch(value.length);
            setDataChart(value);
            setProperty(pr);
            setTitle("Branch Status ");
        });
    }
    return (
        <>
            <div className="content">
                {isLoading === false ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="info"/>
                    </div>
                ) : (
                    <div>
                        <Row>
                            <Col lg="3" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-user-run text-warning"/>
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category"> {t('totalAdminUser')}</p>
                                                    <CardTitle tag="p">{totalUser}</CardTitle>
                                                    <p/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        <button className="btn btn-outline-warning"
                                                style={{width: '100%', fontSize: '20px'}}
                                                onClick={() => userAnalysis()}>
                                            User Status
                                        </button>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-app text-info"></i>
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Total Project</p>
                                                    <CardTitle tag="p">{totalProject}</CardTitle>
                                                    <p/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        <button className="btn btn-outline-info"
                                                style={{width: '100%', fontSize: '20px'}}
                                                onClick={() => projectAnalysis()}>
                                            Project Status
                                        </button>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-money-coins text-success"/>
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Total Province</p>
                                                    <CardTitle tag="p">{totalProvince}</CardTitle>
                                                    <p/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        <button className="btn btn-outline-success " onClick={() => provinceAnalysis()}
                                                style={{width: '100%', fontSize: '20px'}}>
                                            Province Status
                                        </button>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-vector text-danger"/>
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Total Branch</p>
                                                    <CardTitle tag="p">{totalBranch}</CardTitle>
                                                    <p/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        <button className="btn btn-outline-danger"
                                                style={{width: '100%', fontSize: '20px'}}  onClick={()=>branchAnalysis()}>
                                            Branch Status
                                        </button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            {/*chart start*/}
                            <PieChart colorChart={colorChart} dataChart={dataChart} property={property} title={title} totalUsers={totalUsers}/>

                            {/*chart end*/}
                            <Col md="3">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h5">Atarud</CardTitle>
                                        <p className="card-category">
                                            To help improve Afghanistan through the instruction of the English language.
                                        </p>
                                    </CardHeader>
                                    <CardBody style={{height: "300px"}} className="text-center">
                                        <img src={ATARUD} alt="Aria Delta " style={{width:"70%"}}/>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        <div className="stats" style={{textAlign: "justify"}}>
                                            <i className="fa fa-calendar"/>
                                           It is an obvious fact that learning English is the first stepping stone for many students in applying to high standard universities and obtaining good quality degrees in different fields. We, therefore, struggle to help the Afghan students learn English in a practical way so that together we can bring about positive changes and make Afghanistan an educated and advanced country. Education is the most powerful weapon!
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h5">Unicef</CardTitle>
                                        <p className="card-category"> Aria Delta Consulting Group was established by a team of professional consultants, financial analysts, training masters, examinations experts, study abroad consultants and procurement specialists.</p>
                                    </CardHeader>
                                    <CardBody style={{height: "200px"}}>
                                        <img src={ARIADELTA} alt="Unicef " height="200" width="auto"/>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        <div className="stats" style={{textAlign: "justify"}}>
                                            <i className="fa fa-calendar"/>

                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>

                        </Row>
                    </div>
                )}
            </div>
        </>
    );
}

export default Dashboard;
