import React, {useEffect, useRef, forwardRef, useState} from "react";
import "./Report.css"
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, Input
} from "reactstrap";

import {Button} from "react-bootstrap";

import TableData from "./TableData";
import SearchItem from "./SearchItem";
import toast from "react-hot-toast";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Dropdown} from "react-bootstrap";

function Reports() {

    const [reportType, setReportType] = React.useState(0);
    const [list, setList] = React.useState([]);
    const [isLoading, setIsLoading] = useState(1);
    const [headerData, setHeaderData] = React.useState({});

    const [periodDate, setPeriodDate] = React.useState({
        "startDate": "",
        "endDate": "",
        "workDays": "0",
    });
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const refresh=()=>{

    }

    const notifyError = (message) => toast.error(message);
    const notifySuccess = (message) => toast.success(message);
    const notifyLoading = (message) => toast.loading(message,{
        duration: 3000,
    });
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader style={{backgroundColor: "#80808029"}}>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right " style={{whiteSpace:"nowrap"}}>
                                            <h3> Report </h3>

                                            <div className="ml-end" style={{position: "absolute", right: "12px", top: "12px"}}>
                                                <button className="btn btn-dark"
                                                        onClick={() =>{  notifyLoading("Refresh ...");
                                                            Axios.get(mainUrl + 'refreshReport', {headers}).then(res => {
                                                                notifySuccess("Successfully done.");
                                                            }).catch(function (error) {
                                                                notifyError("Something went wrong, please try again later");
                                                            });
                                                        }}>
                                                    &nbsp;
                                                    <span className="nc-icon nc-refresh-69"/>
                                                    &nbsp;
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <SearchItem reportType={reportType} setReportType={setReportType} list={list} setList={setList} isLoading={isLoading} setIsLoading={setIsLoading} headerData={headerData}
                            setHeaderData={setHeaderData} periodDate={periodDate} setPeriodDate={setPeriodDate}
                            />
                        </Card>
                    </Col>
                </Row>
                <TableData reportType={reportType} setReportType={setReportType} list={list} setList={setList} isLoading={isLoading} setIsLoading={setIsLoading} headerData={headerData}
                setHeaderData={setHeaderData} periodDate={periodDate} setPeriodDate={setPeriodDate}
                />
            </div>
        </>
    );
}

export default Reports;
